import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { SubSink } from 'subsink';
import { CALENDAR_LOCALE } from '../../../service/constant';
import {
  toString as lodashToString,
  range as lodashRange,
  chain as lodashChain,
  sumBy as lodashSumBy
} from 'lodash';
import { ApiService } from '../../../service/api.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PurgeService } from '../../../service/purge.service';
import { AuthenticationService } from '../../../service/authentication.service';
import { HttpParams } from '@angular/common/http';
import { HandleTranslateService } from '../../../shared/services';
import { LanguageType } from '../../../shared/interfaces';

@Component({
  selector: 'app-purge-list',
  templateUrl: './purge-list.component.html',
  styleUrl: './purge-list.component.scss'
})
export class PurgeListComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public totalItems: number = 0;
  public itemsPerPage: number = 10;
  public currentPage: number = 1;
  public setPage_size: number = 10;
  public isCheckAll: boolean = false;
  public fileList: any[] = [];
  public selectedList: any[] = [];
  public currentYear: string = moment().format('YYYY');
  public purgeList: any[] = [];
  public calendarLocale = CALENDAR_LOCALE.TH;
  public timeList = [
    {
      label: 'Over 7 years',
      value: moment()
        .subtract(8, 'years')
        .format('YYYY-MM-DD')
    },
    {
      label: '7 years',
      value: moment()
        .subtract(7, 'years')
        .format('YYYY-MM-DD')
    },
    {
      label: '6 years',
      value: moment()
        .subtract(6, 'years')
        .format('YYYY-MM-DD')
    },
    {
      label: '5 years',
      value: moment()
        .subtract(5, 'years')
        .format('YYYY-MM-DD')
    },
    {
      label: '4 years',
      value: moment()
        .subtract(4, 'years')
        .format('YYYY-MM-DD')
    },
    {
      label: 'All Files',
      value: moment().format('YYYY-MM-DD')
    }
  ];

  public monthList = moment.months();
  public selectedYear = moment().format('YYYY');
  public yearList: any[] = [];

  public selectedTime: any = this.timeList[0];

  public startDate!: Date;
  public endDate!: Date;
  public minDate: Date | null | undefined;
  public maxDate: Date | null | undefined;
  public storageList: any[] = [];

  private subs = new SubSink();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private purgeService: PurgeService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private handleTranslateService: HandleTranslateService
  ) {
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initialSetting(): void {
    this.handleTranslateService
      .language
      ?.pipe()
      .subscribe((x) => {
        this.loadTexts();
        this.getStorageDataList(x);
      })
    this.loadTexts();
    this.getCSVList();
    this.getPurgeList();
    const years = lodashRange(
      2010,
      Number(moment().add(1, 'years').format('YYYY')),
      1
    );
    this.yearList = years.map(obj => {
      return {
        value: obj.toString(),
        label: obj.toString()
      };
    });
    this.filterStorageByYear();
  }

  getCSVList(): void {
    const params = new HttpParams()
      .set('page', lodashToString(this.currentPage))
      .set('page_size', lodashToString(this.setPage_size))
      .set('year_lte', this.selectedTime.value ? this.selectedTime.value : this.selectedTime)
      .set('is_purged_selected', 'False');
    this.subs.add(
      this.purgeService
        .getCSVList(params)
        .subscribe({
          next: (res: any) => {
            const reformatedFile = res.results.map((obj: any) =>
              Object.assign(obj, {
                due_date: moment(obj.created_at)
                  .add(7, 'years')
                  .format('DD/MM/YYYY')
              })
            );
            this.totalItems = res.count;
            this.fileList = reformatedFile;
          },
          error: (err) => {
            console.log('error', err)
          }
        })
    );
  }

  getStorageDataList(lang: LanguageType): void {
    const params = new HttpParams()
      .set('none_pagination', 'True')
      .set('year', this.selectedYear);
    this.purgeService
      .getCSVList(params)
      .subscribe({
        next: (res: any) => {
          res = res.filter((obj: any) => obj.file_size);
          const reformattedList = res.map((obj: any) =>
          Object.assign(obj, { month: moment(obj.created_at).format('MM') })
        );
        const monthList = lodashChain(reformattedList)
          .groupBy('month')
          .map((v, i) => {
            return {
              month: moment(i, 'MM').locale(lang as any).format('MMMM'),
              data: v,
              file_size: this.formatBytes(lodashSumBy(v, 'file_size'), 3)
            };
          })
          .value();
        this.storageList = monthList;
        },
        error: (err) => {
          console.log('error', err)
        }
      });
  }

  getPurgeList(): void {
    const params = new HttpParams()
      .set('is_purged_selected', 'True');
    this.subs.add(
      this.purgeService
        .getCSVList(params)
        .subscribe({
          next: (res: any) => {
            this.purgeList = res.results.filter((obj: any) => obj.csv_file);
          },
          error: (err) => {
            console.log('error', err);
          }
        })
    );
  }

  pageChanged(): void {
    this.getCSVList();
  }

  setPageSide(event: any): void {
    this.setPage_size = event;
    this.currentPage = 1;
    this.getCSVList();
  }

  search(): void {
    this.getCSVList();
  }

  clearSearch(): void {
    this.selectedTime = this.timeList[0];
    this.getCSVList();
  }

  checkAll(e: any): void {
    this.fileList.forEach(obj => (obj['is_purged_selected'] = e));
  }

  purgeFile(): void {
    const data = {
      tax_data_list: this.fileList.filter(obj => obj.is_purged_selected).map(obj => obj.id),
      is_purged_selected : true
    };
    this.purgeService
      .patchTaxImport(data)
      .subscribe({
        next: (res) => {
          this.router.navigate(['/purge', 'result']);
        },
        error: (err) => {
          console.log('error', err);
        }
      });
  }

  updateStatus(file: any): void {
    this.selectedList = [...this.selectedList, file.id];
  }

  get yearRange(): string {
    const thisYear = new Date().getFullYear();
    return thisYear - 40 + ':' + thisYear;
  }

  get dateRange(): string {
    const start = moment(this.startDate).format('DD/MM/YYYY');
    const end = moment(this.endDate).format('DD/MM/YYYY');
    return start + ' - ' + end;
  }

  setMinDate(): void {
    this.minDate = this.startDate;
    if (this.endDate < this.minDate) {
      this.endDate = this.minDate;
    }
  }

  setMaxDate(): void {
    this.maxDate = this.endDate;
    if (this.startDate > this.maxDate) {
      this.startDate = this.maxDate;
    }
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  checkSelectedList(): number {
    return this.fileList.filter(obj => obj.is_purged_selected).length;
  }

  formatBytes(bytes: any, decimals: any): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  filterStorageByYear(): void {
    this.getStorageDataList(this.handleTranslateService.currentLanguage());
  }

  loadTexts(): void {
    this.timeList = [];
    this.translateService.get('COMMON.ALL').subscribe(() => {
      this.timeList = [];
      this.timeList.push(
        {label: this.translateService.instant('YEAR.OVER-7-YEARS'), value: moment().subtract(8, 'years').format('YYYY-MM-DD')},
        {label: this.translateService.instant('YEAR.7-YEARS'), value: moment().subtract(7, 'years').format('YYYY-MM-DD')},
        {label: this.translateService.instant('YEAR.6-YEARS'), value: moment().subtract(6, 'years').format('YYYY-MM-DD')},
        {label: this.translateService.instant('YEAR.5-YEARS'), value: moment().subtract(5, 'years').format('YYYY-MM-DD')},
        {label: this.translateService.instant('YEAR.4-YEARS'), value: moment().subtract(4, 'years').format('YYYY-MM-DD')},
        {label: this.translateService.instant('YEAR.ALL-FILES'), value: moment().format('YYYY-MM-DD')}
      );
      }
    );
  }

  isViewer(): boolean {
    return this.authenticationService.isSCBBCMViewer();
  }
}


