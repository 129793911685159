import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PayloadKeyinBuyerDetailModel, PayloadKeyinEndOfBillModel, PayloadKeyinHeaderModel, PayloadKeyinLineItemModel, PayloadKeyinModel, PayloadKeyinReferenceNumberModel, PayloadKeyinSellerBranchModel, VatType } from './keyin.interface';
import { TemplateService } from '../../service/template.service';
import { Subject, first, takeUntil } from 'rxjs';
import { KEYIN_VALIDATORS, OTHER_REASON, VAT_LIST } from './keyin-constant';
import { KeyinService } from './keyin.service';
import { HttpClientService } from '../../service/http-client.service';
import { environment } from '../../../environments/environment';
import { KeyinPreviewPdfModalComponent } from './components';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../shared/services';
import { DocumentTypeModel, LanguageType } from '../../shared/interfaces';
import { DOCUMENT_TYPE } from '../../shared/services/shared.constant';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-keyin',
  templateUrl: './keyin.component.html',
  styleUrl: './keyin.component.scss'
})
export class KeyinComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public step: number = 0;
  public documentTypeList = [ ...DOCUMENT_TYPE ];

  private defaultBuyerType: number = 2;
  private defaultVatType: number = 1;
  private defaultVatPercentage: VatType = '7';

  public validators = KEYIN_VALIDATORS;
  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    doc_type: new FormControl(undefined, [ Validators.required ]),
    doc_no: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.reference_document.doc_no.maxLength) ]),
    running_no: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.reference_document.running_no.maxLength) ]),
    doc_date: new FormControl(new Date(), [ Validators.required ]),
    reference_no: new FormControl(undefined),
    purpose: new FormControl(0, [ Validators.required ]),
    reason: new FormControl(undefined),
    reason_description: new FormControl(undefined),
    reference_abb_no: new FormControl(undefined),
    reference_doc_date: new FormControl(undefined),
    seller_branch: new FormControl(undefined, [ Validators.required ]),
    buyer_type: new FormControl(this.defaultBuyerType, [ Validators.required ]),
    buyer_name: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_name.maxLength) ]),
    buyer_taxid: new FormControl(undefined),
    buyer_branch_code: new FormControl(undefined),
    buyer_branch_name: new FormControl(undefined),
    email_to: new FormControl(undefined),
    address: new FormControl(undefined),
    country: new FormControl(undefined),
    house_no: new FormControl(undefined),
    building_name: new FormControl(undefined),
    moo_no: new FormControl(undefined),
    alley: new FormControl(undefined),
    floor: new FormControl(undefined),
    road: new FormControl(undefined),
    province: new FormControl(undefined),
    district: new FormControl(undefined),
    subdistrict: new FormControl(undefined),
    zip_code: new FormControl(undefined),
    vat_type: new FormControl(this.defaultVatType, [ Validators.required ]),
    vat_percentage: new FormControl(this.defaultVatPercentage, [ Validators.required ]),
    line_items: new FormArray([], [ Validators.minLength(1) ]),
    total: new FormControl(0, [ Validators.required ]),
    discount_or_charge: new FormControl(0, [ Validators.required ]),
    total_amount_after_discount_or_charge: new FormControl(0, [ Validators.required ]),
    base_amount_vat: new FormControl(0, [ Validators.required ]),
    vat_7_percentage: new FormControl(0, [ Validators.required ]),
    base_amount_vat_0_percentage: new FormControl(0, [ Validators.required ]),
    base_amount_non_vat: new FormControl(0, [ Validators.required ]),
    total_amount: new FormControl(0, [ Validators.required ]),
    original_total_amount: new FormControl(undefined),
    new_total_amount: new FormControl(undefined),
    different_amount: new FormControl(undefined)
  });

  public fullTaxInvoiceOption: boolean = false;
  public cnAndDnOption: boolean = false;
  public replacementOption: boolean = false;
  public endOfBillOption: boolean = false;

  public isLoading: boolean = false;
  public isLoadingPreview: boolean = false;

  constructor(
    public templateService: TemplateService,
    private keyinService: KeyinService,
    private httpClientService: HttpClientService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService,
    private handleTranslateService: HandleTranslateService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.checkPermission();
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isKeyinPermission()) {
      this.router.navigate(['/']);
    }
  }

  onSelectDocumentType(documentType: DocumentTypeModel) {
    this.submitted = false;
    const formControl = this.form.controls['doc_type'];
    if (formControl.value !== documentType) {
      this.initialForm();
      this.settingType(documentType.type)
      formControl.setValue(documentType);
    }
    this.step = 1;
  }

  private settingType(event: string | null) {
    if (event === 'credit_note' || event === 'debit_note') {
      this.settingType1();
    } else if (event === 'receipt_tax_invoice') {
      this.settingType2();
    } else {
      this.settingType3();
    }
  }

  private settingType1(): void {
    this.fullTaxInvoiceOption = false;
    this.replacementOption = false;
    this.cnAndDnOption = true;
    this.endOfBillOption = true;
    const fieldsToSet = [
      { field: 'original_total_amount', validators: [ Validators.required ], value: 0 },
      { field: 'new_total_amount', validators: [ Validators.required ], value: 0 },
      { field: 'different_amount', validators: [ Validators.required ], value: 0 },
      { field: 'purpose', validators: [ Validators.required ], value: 1 },
      { field: 'reference_no', validators: [ Validators.required ] },
      { field: 'reason', validators: [ Validators.required ] }
    ];
    const fieldsToClear = [
      'reference_abb_no',
      'reference_doc_date'
    ];
    this.utilitiesService.manageFormState(this.form, fieldsToSet);
    this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
  }

  private settingType2(): void {
    this.fullTaxInvoiceOption = true;
    this.replacementOption = false;
    this.cnAndDnOption = false;
    this.endOfBillOption = false;
    const fieldsToSet = [
      { field: 'purpose', validators: [ Validators.required ], value: 0 }
    ];
    const fieldsToClear = [
      'original_total_amount',
      'new_total_amount',
      'different_amount',
      'reference_no',
      'reason',
      'reason_description',
      'reference_abb_no',
      'reference_doc_date'
    ];
    this.utilitiesService.manageFormState(this.form, fieldsToSet);
    this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
  }

  private settingType3(): void {
    this.fullTaxInvoiceOption = false;
    this.replacementOption = true;
    this.cnAndDnOption = false;
    this.endOfBillOption = false;
    const fieldsToSet = [
      { field: 'purpose', validators: [ Validators.required ], value: 0 }
    ];
    const fieldsToClear = [
      'original_total_amount',
      'new_total_amount',
      'different_amount',
      'reference_no',
      'reason',
      'reason_description',
      'reference_abb_no',
      'reference_doc_date'
    ];
    this.utilitiesService.manageFormState(this.form, fieldsToSet);
    this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
  }

  public nextStep(index: number) {
    this.step = index;
  }

  public onGenerate(): void {   
    this.submitted = true;
    if (this.form.invalid) return;
    this.handleModalService.confirmModal({
      title: 'NEW-TRANSLATE.COMMON.CONFIRMATION',
      texts: [
        { text: 'NEW-TRANSLATE.KEYIN.PLEASE-RECHECK-THE-DOCUMENT-DETAIL-BEFORE-SUBMISSION', margin: '0' },
        { text: 'NEW-TRANSLATE.MODAL-TEXT.ARE-YOU-SURE-TO-CREATE-THIS-DOCUEMNT', margin: '0' }
      ],
      cancelButton: {
        show: true,
        id: 'cancelModalButton',
        name: 'NEW-TRANSLATE.MODAL-TEXT.NO'
      },
      confirmButton: {
        show: true,
        id: 'confirmModalButton',
        name: 'NEW-TRANSLATE.COMMON.YES'
      }
    })
    .result
    .then(
      () => {
        this.previewPdf();
      }
    );
  }

  private previewPdf(): void {
    this.isLoadingPreview = true;
    const data = this.getGenerateValue();
    this.httpClientService
      .postBlob(`${ environment.apiURL }/api/keyin/preview_pdf`, data)
      .pipe()
      .subscribe({
        next: (res) => {
          this.isLoadingPreview = false;
          const modalEl = this.handleModalService
            .openModal(
              { 
                componentRef: KeyinPreviewPdfModalComponent,
                id: 'previewPdfModal',
                options: {
                  windowClass: 'preview-pdf-modal',
                  centered: true,
                  size: 'xl',
                  scrollable: true,
                  keyboard: false,
                  backdrop: 'static',
                  autoCloseRoutingChange: true
                },
                value: {
                  pdf: res
                }
              }
            )
          this.onCreate(data, modalEl)
        },
        error: (err) => {
          console.error(err);
          this.isLoadingPreview = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            if (err.error instanceof Blob) {
              this.utilitiesService
                .convertBlobToJson(err.error)
                .subscribe({
                  next: (json) => {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(json);
                    this.handleModalService.connectFailedModal(errorMessage);
                  },
                  error: () => {
                    this.handleModalService.connectFailedModal();
                  }
                });
            } else {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        }
      });
  }

  private onCreate(data: PayloadKeyinModel, modalEl: NgbModalRef): void {
    const modalComponent = (modalEl.componentInstance as KeyinPreviewPdfModalComponent)
          modalComponent
            .onSubmitEvent
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              () => {
                modalComponent.isLoading = true;
                this.httpClientService
                  .post(`${ environment.apiURL }/api/keyin/create_document`, data)
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      this.step = 2;
                      modalComponent.onClose();
                      modalComponent.isLoading = false;
                    },
                    error: (err) => {
                      console.error(err);
                      modalComponent.isLoading = false;
                      if (!this.handleModalService.hasModal('failedModal')) {
                        const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
                        this.handleModalService.connectFailedModal(errorMessage);
                      }
                    }
                  });
              }
            );
  }

  private getGenerateValue(): PayloadKeyinModel {
    const formValue = this.form.value;
    return {
      file_type: formValue['doc_type'].type,
      document_number: formValue['running_no'],
      data: {
        header: this.getHeaderValue(),
        reference: this.getReference(),
        seller: this.getSellerValue(),
        buyer: this.getBuyerDetail(),
        line_items: this.getLineItemsValue(),
        total_items: this.getEndOfBillValue(),
        receiver_email: formValue['email_to'] ? formValue['email_to'].join(',') : undefined
      }
    };
  }

  private getReference(): PayloadKeyinReferenceNumberModel {
    const formValue = this.form.value;
    return {
      document_id: formValue['reference_no'] ? formValue['reference_no'] instanceof Array ? formValue['reference_no']?.join(',') : formValue['reference_no'] : '',
      abb_no: formValue['reference_abb_no'] ?? undefined,
      abb_date: formValue['reference_doc_date'] ? this.utilitiesService.transformDateForPayload(this.utilitiesService.setDateHours(formValue['reference_doc_date'], 0, 0, 0, 0), '+07:00') : undefined
    }
  } 

  private getHeaderValue(): PayloadKeyinHeaderModel {
    const formValue = this.form.value;
    return {
      invoice_number: formValue['doc_no'],
      issue_dt: this.utilitiesService.transformDateForPayload(this.utilitiesService.setDateHours(formValue['doc_date'], 0, 0, 0, 0), '+07:00'),
      creation_dt: this.utilitiesService.transformDateForPayload(new Date(), '+07:00'),
      include_note_subject: '',
      include_note_content: '',
      purpose: formValue['reason'] === OTHER_REASON.name_th ? formValue['reason_description'] : formValue['reason'],
      is_replacement: formValue['purpose'] === 2 ? true : false
    };
  }

  private getSellerValue(): PayloadKeyinSellerBranchModel {
    const formValue = this.form.value;
    return {
      name: formValue['seller_branch']['company_name'],
      branch_name: formValue['seller_branch']['branch_name'],
      branch_code: formValue['seller_branch']['internal_branch_code'],
      tax_id: formValue['seller_branch']['company_tax_id'] + formValue['seller_branch']['branch_code'],
      address: formValue['seller_branch']['address_1'],
      post_code: formValue['seller_branch']['zip_code'],
      branch_tel: formValue['seller_branch']['branch_tel'] ?? ''
    };
  }

  private getBuyerDetail(): PayloadKeyinBuyerDetailModel {
    const formValue = this.form.value;
    return {
      name: formValue['buyer_name'],
      tax_id: formValue['buyer_type'] === 2 ? formValue['buyer_taxid'] + formValue['buyer_branch_code'] : formValue['buyer_taxid'],
      address: formValue['buyer_type'] === 3 ? formValue['address'] : this.keyinService.transformAddress(formValue, 'th'),
      post_code: formValue['zip_code'] ?? '',
      country_code: formValue['buyer_type'] === 3 ? formValue['country']?.country_code : 'TH',
      type: formValue['buyer_type'],
      branch_name: formValue['buyer_branch_name']
    };
  }

  private getLineItemsValue(): PayloadKeyinLineItemModel[] {
    const formValue = this.form.value;
    const lineItems = formValue['line_items'].map((x: any) => {
      return {
        item_number: x['id'],
        product_name: x['description'],
        product_id: x['product_code'],
        info_note_subject: null,
        info_note_content: null,
        quantity: Number(x['qty']),
        unit: x['unit'],
        unit_price: x['unit_price']?.toString(),
        discount_or_charge_reason_per_piece: null,
        discount_or_charge_per_piece: '0',
        total_amount_per_piece: x['unit_price']?.toString(),
        net_discount_or_charge_reason: this.getDiscountOrChart(x['discount'], x['charge']),
        net_discount_or_charge: (x['discount'] !== undefined && x['discount'] !== null && x['discount'] !== 0 && x['discount'] !== '0')
                                  ? x['discount'].toString()
                                  : (x['charge'] !== undefined && x['charge'] !== null && x['charge'] !== 0 && x['discount'] !== '0'
                                      ? x['charge'].toString()
                                      : '0'),
        net_total_amount: x['amount_exclude']?.toString(),
        percent_vat: x['vat_percentage'] === VAT_LIST[2] ? null : x['vat_percentage'],
        vat: x['total_vat']?.toString(),
        net_line_exclude_vat: x['amount_exclude']?.toString(),
        total_payment: x['total_include']?.toString()
      };
    });
    return lineItems;
  }

  private getDiscountOrChart(discount: string | number | null | undefined, charge: string | number | null | undefined): string | null {
    if ((typeof discount === 'number' && discount > 0) || (typeof discount === 'string' && discount !== '0')) {
      return 'ส่วนลดจากราคาปกติ';
    } else if ((typeof charge === 'number' && charge > 0) || (typeof charge === 'string' && discount !== '0')) {
      return 'ค่าธรรมเนียมอื่นๆ'
    } else {
      return null;
    }
  }

  private getMaxPercentVat(): string | null {
    const formValue = this.form.value;
    const lineItems: any[] = formValue['line_items']
    let max_percent_vat: string | null = null
    for (const item of lineItems) {
      const percent_vat = item['vat_percentage'] === VAT_LIST[2] ? null : item['vat_percentage']
      if (max_percent_vat === null || max_percent_vat < percent_vat) {
        max_percent_vat = percent_vat
      }
    }
    return max_percent_vat
  }

  private getEndOfBillValue(): PayloadKeyinEndOfBillModel {
    const formValue = this.form.value;
    return {
      line_total_amount: formValue['total'],
      discount_or_charge_reason: '',
      discount_or_charge: formValue['discount_or_charge']?.toString(),
      line_total_amount_after_discount: formValue['total_amount_after_discount_or_charge']?.toString(),
      percent_vat: this.getMaxPercentVat(),
      vat_basis_amount: formValue['base_amount_vat']?.toString(),
      vat: formValue['vat_7_percentage']?.toString(),
      vat0_amount: formValue['base_amount_vat_0_percentage']?.toString(),
      non_vat_amount: formValue['base_amount_non_vat']?.toString(),
      total_payment: formValue['total_amount']?.toString(),
      payment_term_type_code: '',
      payment_term_description: '',
      payment_term_due_date_time: null,
      original_amount: formValue['original_total_amount']?.toString(),
      new_amount: formValue['new_total_amount']?.toString(),
      difference_amount: formValue['different_amount']?.toString()
    };
  }

  private initialForm(): void {
    const formControls = this.form.controls;
          formControls['doc_no'].setValue(undefined);
          formControls['running_no'].setValue(undefined);
          formControls['doc_date'].setValue(new Date());
          formControls['reason'].setValue(undefined);
          formControls['reason_description'].setValue(undefined);
          formControls['reference_abb_no'].setValue(undefined);
          formControls['reference_doc_date'].setValue(undefined);
          formControls['seller_branch'].setValue(undefined);
          formControls['buyer_type'].setValue(this.defaultBuyerType);
          formControls['buyer_name'].setValue(undefined);
          formControls['buyer_taxid'].setValue(undefined);
          formControls['buyer_branch_code'].setValue(undefined);
          formControls['buyer_branch_name'].setValue(undefined);
          formControls['email_to'].setValue(undefined);
          formControls['address'].setValue(undefined);
          formControls['country'].setValue(undefined);
          formControls['house_no'].setValue(undefined);
          formControls['building_name'].setValue(undefined);
          formControls['moo_no'].setValue(undefined);
          formControls['alley'].setValue(undefined);
          formControls['floor'].setValue(undefined);
          formControls['road'].setValue(undefined);
          formControls['province'].setValue(undefined);
          formControls['district'].setValue(undefined);
          formControls['subdistrict'].setValue(undefined);
          formControls['zip_code'].setValue(undefined);
          formControls['vat_type'].setValue(this.defaultVatType);
          formControls['vat_percentage'].setValue(this.defaultVatPercentage);
          (formControls['line_items'] as FormArray).clear();
          (formControls['line_items'] as FormArray).push(this.getDefaultLineItemsFormGroup());
          formControls['total'].setValue(0);
          formControls['discount_or_charge'].setValue(0);
          formControls['total_amount_after_discount_or_charge'].setValue(0);
          formControls['base_amount_vat'].setValue(0);
          formControls['vat_7_percentage'].setValue(0);
          formControls['base_amount_vat_0_percentage'].setValue(0);
          formControls['base_amount_non_vat'].setValue(0);
          formControls['total_amount'].setValue(0);
  }

  public getDefaultLineItemsFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.line_items.id.maxLength) ]),
      product_code: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.line_items.product_code.maxLength) ]),
      description: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.line_items.description.maxLength) ]),
      qty: new FormControl(0, [ Validators.required ]),
      unit: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.line_items.unit.maxLength) ]),
      unit_price: new FormControl(0, [ Validators.required ]),
      discount: new FormControl(0, [ Validators.required ]),
      charge: new FormControl(0, [ Validators.required ]),
      amount_exclude: new FormControl(0, [ Validators.required ]),
      vat_percentage: new FormControl(this.defaultVatPercentage, [ Validators.required ]),
      total_vat: new FormControl(0, [ Validators.required ]),
      total_include: new FormControl(0, [ Validators.required ])
    });
  }

  public onClose(): void {
    this.initialForm();
    this.form.controls['doc_type'].reset();
    this.step = 0;
    this.submitted = false;
  }

}
