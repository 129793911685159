import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypeModel } from '../interfaces';

@Pipe({
  name: 'documentType'
})
export class DocumentTypePipe implements PipeTransform {

  transform(value: string | null | undefined, documentType: DocumentTypeModel[], language: string | null | undefined, type?: 'name' | 'id'): string | null | DocumentTypeModel {
    const documentTypeValue: DocumentTypeModel | undefined = documentType.find(x => x.type === value || x.name_en === value || x.name_th === value);
    if(documentTypeValue) {
      if(type === 'name') return language === 'th' ? documentTypeValue.name_th : documentTypeValue.name_en;
      if(type === 'id') return documentTypeValue.type;
      return documentTypeValue;
    } else {
      if (value) return value;
      return '-';
    }
  }

}
