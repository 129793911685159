@if (message) {
  <div class="announcement-message-container mt-3">
    <div class="d-flex align-items-center gap-2 mb-3">
      <fa-icon
        class="announcement-icon"
        [icon]="['fas','circle-exclamation']"
        size="2xl"
      ></fa-icon>
      <h5 class="m-0">Announcement</h5>
    </div>
    <div class="announcement-message-wrapper">{{ message }}</div>
  </div>
}
