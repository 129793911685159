<p-toast position="top-right"></p-toast>
<div class="container-fluid login-container">
  <div class="row align-items-sm-center login-container-row ">
    <div class="col-12 col-sm-6 col-md-6 col-lg-5 title-block d-flex d-sm-block flex-column justify-content-end pb-3">
      <div class="d-none d-sm-block">
        <div class="e-tax-title mt-2">SCB E-TAX INVOICE & E-RECEIPT</div>
        <div class="e-tax-subtitle">One Stop Service for E-Tax Invoice & Receipt</div>
      </div>
      <div class="d-block d-sm-none">
        <img style="height:50px;" src="../../assets/images/scb-logo.png" alt="Logo">
      </div>
      
      <app-announcement-message/>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-5 offset-lg-2 px-lg-5">
      <img
        class="d-none d-sm-block mb-3" style="height:50px;"
        src="../../assets/images/scb-logo.png" alt="Logo"
      >

      <div class="welcome-text mt-sm-4">Welcome</div>
      Please login to your account.

      <div class="form-group mt-2 mb-sm-2 mb-3">
        <input class="form-control form-control-lg" id="username" name="username" placeholder="Username"
               type="text" [(ngModel)]="username" (keyup.enter)="login()">
      </div>
      <div class="form-group m-0">
        <input class="form-control form-control-lg" id="password" name="password" placeholder="Password"
               type="password" [(ngModel)]="password" (keyup.enter)="login()">
      </div>

      <div class="text-red text-center my-1 d-block">
        {{ errorMessage ? errorMessage : '&nbsp;' | translate }}
      </div>

      <div class="d-flex align-items-center">
        <button class="btn btn-dv btn-wider" (click)="login()">
          Login
        </button>
        <div class="ms-3 reset-password" (click)="forgotPassword(fgPassword)">
          Forgot password ?
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fgPassword let-modal>
  <div class="modal-body">
    <h4>Request New Password</h4>

    <div class="form-group mt-2 mb-sm-2">
      <label for="email-fg">E-mail</label>
      <input class="form-control form-control-lg" id="email-fg" name="e-mail" placeholder="E-mail"
             type="text" [(ngModel)]="resetEmail" (keyup.enter)="resetPassword()">
    </div>
    <div class="form-group mt-2 mb-sm-2">
      <label for="username-fg">Username</label>
      <input class="form-control form-control-lg" id="username-fg" name="username" placeholder="Username"
             type="text" [(ngModel)]="resetUsername" (keyup.enter)="resetPassword()">
    </div>
    <div *ngIf="!isLoading">
      <re-captcha #recaptchaRef (resolved)="recaptChaResolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
    </div>

    <div class="text-red" *ngIf="errorForgotpassword">
      {{errorForgotpassword}}
    </div>
  </div>
  <div class="modal-footer-alternative">
    <div class="d-flex justify-content-end">
      <button class="btn btn-dv btn-wide" [disabled]="!resetUsername || !resetEmail || !recaptchaToken || isLoading" (click)="resetPassword()">
        <fa-icon *ngIf="isLoading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          Confirm
      </button>
      <button class="btn btn-dv btn-wide btn-clean ms-3" (click)="close()">
        Cancel
      </button>
    </div>
  </div>

</ng-template>
