import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, map, takeUntil } from 'rxjs';
import { HttpClientService } from '../../../../service/http-client.service';
import { environment } from '../../../../../environments/environment';
import { KEYIN_VALIDATORS, OTHER_REASON } from '../../keyin-constant';
import { LanguageType, LookupModel } from '../../../../shared/interfaces';
import { UtilitiesService, HandleTranslateService } from '../../../../shared/services';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { CUSTOM_PATTERN_CHARACTOR_AND_NUMBER } from '../../../../shared/services/shared.constant';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-keyin-section-1',
  templateUrl: './keyin-section-1.component.html',
  styleUrls: [ './keyin-section-1.component.scss', '../../keyin.component.scss' ]
})
export class KeyinSection1Component implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();
  public language: LanguageType

  public validators = KEYIN_VALIDATORS;

  @Input() public fullTaxInvoiceOption: boolean = false;
  @Input() public cnAndDnOption: boolean = false;
  @Input() public replacementOption: boolean = false;

  @Input() public form!: FormGroup;
  @Input() public submitted: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public documentType!: string;

  public reasons: LookupModel[] = [];
  public otherReason: LookupModel = OTHER_REASON;

  public customPatterns = CUSTOM_PATTERN_CHARACTOR_AND_NUMBER;

  public isLoadingReason: boolean = false;

  constructor(
    private handleTranslateService: HandleTranslateService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
   this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private initialSetting(): void {
    this.loadReason();
  }

  private loadReason(): void {
    this.isLoadingReason = true;
    this.httpClientService
      .get(`${ environment.apiURL }/api/keyin/document/${ this.documentType }/purpose_dropdown`)
      .pipe(
        map(x => x as LookupModel[])
      )
      .subscribe({
        next: (res) => {
          this.reasons = [ ...res, ...[ this.otherReason ] ];
          this.isLoadingReason = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  public onChangeReason(event: LookupModel): void {
    if (event.name_th === this.otherReason.name_th) {
      this.utilitiesService.manageFormState(this.form, [ { field: 'reason_description', validators: [ Validators.required, Validators.maxLength(this.validators.reference_document.reason_description.maxLength) ] } ]);
    } else {
      this.utilitiesService.clearValidatorsAndReset(this.form, [ 'reason_description' ]);
    }
  }

  onChangePurpose(event: RadioButtonClickEvent) {
    if (event.value === 0) {
      const fieldsToClear = [
        'reference_abb_no',
        'reference_doc_date',
        'reference_no',
        'reason',
        'reason_description'
      ];
      this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    } else if (event.value === 1) {
      const fieldsToSet = [
        { field: 'reference_no', validators: [ Validators.required, Validators.maxLength(this.validators.reference_document.reference_no.maxLength) ] },
        { field: 'reason', validators: [ Validators.required ] },
        { field: 'reason_description' }
      ];
      const fieldsToClear = [
        'reference_abb_no',
        'reference_doc_date'
      ];
      this.utilitiesService.manageFormState(this.form, fieldsToSet);
      this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    } else if (event.value === 2) {
      const fieldsToSet = [
        { field: 'reference_no', validators: [ Validators.required, Validators.maxLength(this.validators.reference_document.reference_no.maxLength) ] },
        { field: 'reason', validators: [ Validators.required ] },
        { field: 'reason_description' }
      ];
      const fieldsToClear = [
        'reference_abb_no',
        'reference_doc_date',
      ];
      this.utilitiesService.manageFormState(this.form, fieldsToSet);
      this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    } else if (event.value === 3) {
      const fieldsToSet = [
        { field: 'reference_abb_no', validators: [ Validators.required, Validators.maxLength(this.validators.reference_document.reference_abb_no.maxLength) ] },
        { field: 'reference_doc_date', validators: [ Validators.required ] }
      ];
      const fieldsToClear = [
        'reference_no',
        'reason',
        'reason_description'
      ];
      this.utilitiesService.manageFormState(this.form, fieldsToSet);
      this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    }
  }

  onChangeReferenceNumber(event: string[], tooltip: NgbTooltip) {
    if (event && event.length === 0) {
      setTimeout(() => {
        if (tooltip) {
          tooltip.toggle();
        }
      }, 100);
    }
  }

}
