import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { ChangepasswordModule } from './core/changepassword/changepassword.module';
import { ToastModule } from 'primeng/toast';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MainModule } from './main/main.module';
import { AppRoutingModule } from './app-routing.module';
import { FontawesomeService } from './shared/services';
import { SharedComponentModule } from './shared/components/shared-component.module';
import { DefaultLanguage, HttpLoaderFactory } from './shared/services/translate-service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MainModule,
    NgbModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: DefaultLanguage(),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    ChangepasswordModule,
    ToastModule,
    NgIdleKeepaliveModule.forRoot(),
    FontAwesomeModule,
    NgxMaskDirective,
    SharedComponentModule
  ],
  providers: [
    provideHttpClient(),
    FontawesomeService,
    provideNgxMask()
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
