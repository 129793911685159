<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.DASHBOARD.TITLE' | translate }}</h3>
    </div>
    <form [formGroup]="form" class="form-wrapper float-right">
      <div class="row g-2">
        @if (isBCMStaff) {
          <div class="company-dropdown">
            <p-dropdown
              class="dv-dropdown"
              formControlName="corporate_id"
              selectId="companySelect"
              [options]="companies"
              optionValue="id"
              [loading]="isLoading || isLoadingChart"
              (onChange)="loadDashboard()"
              [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
            >
              <ng-template let-item pTemplate="selectedItem">
                <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
              </ng-template>
            </p-dropdown>
          </div>
        }
        <div class="year-dropdown">
          <p-dropdown
            class="dv-dropdown"
            formControlName="year"
            selectId="yearSelect"
            [options]="years"
            [loading]="isLoading || isLoadingChart"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
            (onChange)="loadDashboard()"
          >
          </p-dropdown>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="dashboard-container">
  <div class="chart-container">
    <canvas
      [ngClass]="[ isLoading ? 'd-none' : 'd-block' ]"
      data-testid="chartOfDashboard"
      id="chartOfDashboard"
    >{{ chartOfDashboard }}</canvas>
    @if (isLoading) {
      <div class="loading-container">
        <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="2xl"></fa-icon>
      </div>
    }
  </div>
</div>