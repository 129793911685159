import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { NotificationService } from '../notification.service';
import { TranslateService } from '@ngx-translate/core';

export const RequireResetPasswordGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  const canActiveChild = ! authenticationService.requireToChangePassword()

  if(canActiveChild === false){
    notificationService.showCustomNotification({
      severity: 'info',
      summary: translateService.instant('DATA-PURGE.INFO'),
      detail: translateService.instant('PROFILE.PASSWORD.REQUIRE-RESET-PASSWORD')
    })
    router.navigate(['/profile' , 'password'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  return true;
};

