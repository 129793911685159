import { LookupModel } from "../interfaces";

export const MONTHS: LookupModel[] =  [
  {
    id: 1,
    name_th: 'มกราคม',
    name_en: 'January'
  },
  {
    id: 2,
    name_th: 'กุมภาพันธ์',
    name_en: 'February'
  },
  {
    id: 3,
    name_th: 'มีนาคม',
    name_en: 'March'
  },
  {
    id: 4,
    name_th: 'เมษายน',
    name_en: 'April'
  },
  {
    id: 5,
    name_th: 'พฤษภาคม',
    name_en: 'May'
  },
  {
    id: 6,
    name_th: 'มิถุนายน',
    name_en: 'June'
  },
  {
    id: 7,
    name_th: 'กรกฎาคม',
    name_en: 'July'
  },
  {
    id: 8,
    name_th: 'สิงหาคม',
    name_en: 'August'
  },
  {
    id: 9,
    name_th: 'กันยายน',
    name_en: 'September'
  },
  {
    id: 10,
    name_th: 'ตุลาคม',
    name_en: 'October'
  },
  {
    id: 11,
    name_th: 'พฤศจิกายน',
    name_en: 'November'
  },
  {
    id: 12,
    name_th: 'ธันวาคม',
    name_en: 'December'
  }
];

export const STATUS: LookupModel[] = [
  {
    id: 2,
    name_en: 'Success',
    name_th: 'สำเร็จ'
  },
  {
    id: 3,
    name_en: 'Error',
    name_th: 'ผิดพลาด'
  },
  {
    id: 4,
    name_en: 'Pending',
    name_th: 'รอดำเนินการ'
  },
  {
    id: 6,
    name_en: 'Accepted',
    name_th: 'ได้รับแล้ว'
  },
  {
    id: 7,
    name_en: 'Rejected',
    name_th: 'ปฏิเสธ'
  }
];

export const DOCUMENT_TYPE = [
  {
    name_th: 'ใบกำกับภาษี',
    name_en: 'Tax Invoice',
    type: 'tax_invoice'
  },
  {
    name_th: 'ใบเสร็จรับเงิน',
    name_en: 'Receipt',
    type: 'receipt'
  },
  {
    name_th: 'ใบลดหนี้',
    name_en: 'Credit Note',
    type: 'credit_note'
  },
  {
    name_th: 'ใบเพิ่มหนี้',
    name_en: 'Debit Note',
    type: 'debit_note'
  },
  {
    name_th: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
    name_en: 'Receipt/Tax Invoice',
    type: 'receipt_tax_invoice'
  },
  {
    name_th: 'ใบส่งของ/ใบกำกับภาษี',
    name_en: 'Delivery Order/Tax Invoice',
    type: 'delivery_order_tax_invoice'
  },
  {
    name_th: 'ใบแจ้งหนี้',
    name_en: 'invoice',
    type: 'invoice'
  },
  {
    name_th: 'ใบแจ้งหนี้/ใบกำกับภาษี',
    name_en: 'Invoice/Tax Invoice',
    type: 'invoice_tax_invoice'
  }
];

export const CUSTOM_PATTERN_CHARACTOR_AND_NUMBER = { A: { pattern: new RegExp('^[A-Za-z0-9!@#$%^&*()_+=\\[\\]{}|;:\'",.<>?/`~\\-]*$') } };
export const CUSTOM_PATTERN_NUMBER = { S: { pattern: new RegExp(/[0-9,-]/) } };

export const ENCODING_TYPE = [
  'UTF-8',
  'WINDOWS-874'
];