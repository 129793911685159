import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNull as lodashIsNull, isEmpty as lodashIsEmpty, get as lodashGet } from 'lodash';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-validate-otp',
  templateUrl: './validate-otp.component.html',
  styleUrl: './validate-otp.component.scss'
})
export class ValidateOtpComponent implements OnInit {

  public msg: string | null = 'Validating OTP';
  public otp: string | null = null;
  public isLoading: boolean = true;
  public showRequestOTP: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.handleParams();
  }

  handleParams(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.otp = params['password'];
      if(lodashIsNull(this.otp) || lodashIsEmpty(this.otp)) {
        this.isLoading = false;
        this.msg = 'OTP is empty, failed.';
        return;
      }
      this.validateOTP(this.otp);
    });
  }

  validateOTP(otp: string) {
    this.authenticationService
      .validateOTP(otp)
      .subscribe({
        next: (res) => {
          const token = lodashGet(res, 'token');
          localStorage.clear();
          localStorage.setItem('currentUser', token ?? '');
          this.authenticationService.updateUserInfo(res);
          if (token) {
            this.router.navigate(['']);
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.showRequestOTP = true;
          this.msg = err.error['detail'];
        }
      });
  }

}
