<div class="container-fluid login-container">
  <div class="row align-items-center login-container-row">
    <div class="col-sm-4 col-lg-3 title-block d-none d-sm-block">
      One Stop Service for
      <div class="e-tax-title mt-2">E-TAX</div>
      <div class="e-tax-subtitle">Invoice & Receipt</div>
    </div>
    <div class="col-sm-8 col-lg-4 offset-lg-4 px-lg-5">
      <img src="../../assets/images/login-logo.png">
      <div class="welcome-text mt-sm-4">
        {{ msg }}
        <fa-icon [hidden]="!isLoading" class="spinner-border" [icon]="['fas','spinner']"></fa-icon>
      </div>
      <div [hidden]="!showRequestOTP" class="form-group">
        <a [routerLink]="['/login']" class="btn btn-dv btn-block">Request OTP</a>
      </div>
      <div class="float-right mt-sm-2 mt-lg-4">
        <div class="footer-text">Powered by</div>
        <img src="../../assets/images/dv-logo.png">
      </div>
    </div>
  </div>
</div>
