<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.SUBMIT-TO-RD.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="submit-to-rd-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows || isLoadingOther"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate"
                selectId="companySelect"
                [options]="companies"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                (onChange)="filterBranches($event.value)"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_branch_id"
                selectId="branchSelect"
                [options]="branches"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.COMMON.BRANCH' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="branchSelect">{{ 'NEW-TRANSLATE.COMMON.BRANCH' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="rd_document_type"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="documentType"
                optionValue="type"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="documentTypeSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="documentTypeSelect">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['month'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="month"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="months"
                optionValue="id"
                selectId="monthSelect"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="monthSelect">{{ 'NEW-TRANSLATE.COMMON.MONTH' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['year'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="year"
                selectId="yearSelect"
                [options]="years"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
              </p-dropdown>
              <label for="yearSelect">{{ 'NEW-TRANSLATE.COMMON.YEAR' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-8 d-flex align-items-center gap-1">
            <p-checkbox
              binary="true"
              class="dv-checkbox"
              inputId="notSentCheckbox"
              formControlName="not_sent_only"
              [readonly]="isLoading || isLoadingRows || isLoadingOther"
            ></p-checkbox>
            <label for="notSentCheckbox">{{'NEW-TRANSLATE.SUBMIT-TO-RD.ONLY-ITEMS-THAT-HAVENT-BEEN-SENT-COMPLETELY' | translate }}</label>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    >
      @if (isPermissionDatatableTools()) {
        <div class="col-auto d-flex gap-2 mt-3 mt-md-0">
          <button
            class="btn btn-dv btn-wide"
            data-testid="downloadExcelButton"
            [disabled]="!rows || rows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            id="downloadExcelButton"
            type="button"
            [title]="'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-EXCEL' | translate"
            (click)="onDownloadExcel()"
          >
            {{ 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-EXCEL' | translate }}
          </button>
          <button
            class="btn btn-dv btn-wide"
            data-testid="submitToRdButton"
            [disabled]="!rows || rows.length === 0 || !selectedRows || selectedRows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            id="submitToRdButton"
            type="button"
            [title]="'NEW-TRANSLATE.SUBMIT-TO-RD.SUBMIT-TO-RD' | translate"
            (click)="onSubmitToRd()"
          >
            {{ 'NEW-TRANSLATE.SUBMIT-TO-RD.SUBMIT-TO-RD' | translate }}
          </button>
        </div>
      }
    </app-custom-pagination>
    <app-datatables
      tableId="submitToRdDatatable"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [columns]="columns"
      [blockRenderingCheckboxKey]="blockRenderingCheckboxKey"
      [blockRenderingCheckboxBoolean]="blockRenderingCheckboxBoolean"
      [rows]="rows"
      [(selectedRows)]="selectedRows"
      [pages]="pages"
      [limitRow]="limitRow"
      [collectionSize]="collectionSize"
    />
  </div>
</div>