import { Component } from "@angular/core";
import { Observable, Subject, first, interval, map, switchMap, takeUntil, takeWhile } from "rxjs";
import { DocumentTypeModel, LanguageType } from "../../shared/interfaces";
import { DOCUMENT_TYPE, ENCODING_TYPE } from "../../shared/services/shared.constant";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HandleTranslateService } from "../../shared/services";
import { HttpClientService } from "../../service/http-client.service";
import { environment } from "../../../environments/environment";
import { DocumentUploadErrorsModel, ResponseMessageBackgroundJobStatusModel, ResponseMessageGenerateXmlOrPdfA3Model, ResponseMessageGetCsvSummarModel } from "./document-upload.interface";
import { AuthenticationService } from "../../service/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrl: './document-upload.component.scss'
})
export class DocumentUploadComponent {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public step: number = 0;
  public documentTypeList = [ ...DOCUMENT_TYPE ];
  public encodingType = ENCODING_TYPE;

  public limitSize: number = 52428800;
  public fileTypes: { type: string, value: string }[] = [
    { type: '.csv', value: 'CSV' },
    { type: 'text/csv', value: 'CSV' },
    { type: 'application/vnd.ms-excel', value: 'XLS' },
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', value: 'XLSX' },
    { type: '.xls', value: 'XLS' },
    { type: '.xlsx', value: 'XLSX' }
  ];
  public acceptTypes: string = this.fileTypes.map(x => x.type).join(',');

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    doc_type: new FormControl(undefined, [ Validators.required ]),
    encoding: new FormControl(undefined)
  });
  public file: File | undefined;

  public totalDocument: number = 0;
  public totalPayment: number = 0;
  public totalVat: number = 0;

  public uploadProgress: number = 0;
  public isLoadingUploadStatus: boolean = false;

  public isError: boolean = false;
  public errorMessage: string | undefined;
  public errorsMessage: DocumentUploadErrorsModel[] = [];

  public isUploadSuccess: boolean = false;
  public taxImportId: number | undefined;

  public isLoadingGenerate: boolean = false;
  public isLoadingSummary: boolean = false;
  
  constructor(
    private handleTranslateService: HandleTranslateService,
    private httpClientService: HttpClientService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.checkPermission();
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isDocumentUploadPermission()) {
      this.router.navigate(['/']);
    }
  }

  public onSelectDocumentType(documentType: DocumentTypeModel) {
    this.submitted = false;
    const formControl = this.form.controls['doc_type'];
    if (formControl.value !== documentType) {
      this.onClear();
      formControl.setValue(documentType);
    }
    this.step = 1;
  }

  public nextStep(index: number) {
    this.step = index;
  }

  onChangeFile(event: File) {
    if (event.type === 'text/csv') {
      this.form.controls['encoding'].setValue(this.encodingType[0]);
      this.form.controls['encoding'].setValidators([ Validators.required ]);
    } else {
      this.form.controls['encoding'].setValue(undefined);
      this.form.controls['encoding'].clearValidators();
    }
    this.form.controls['encoding'].updateValueAndValidity();
  }

  public onRemoveFile(): void {
    this.onClear();
  }

  private onClear(): void {
    this.clearErrorMessage();
    this.file = undefined;
    this.totalDocument = 0;
    this.totalPayment = 0;
    this.totalVat = 0;
    this.uploadProgress = 0;
    this.isLoadingUploadStatus = false;
    this.isUploadSuccess = false;
    this.taxImportId = undefined;
    this.isLoadingGenerate = false;
    this.isLoadingSummary = false;
    this.form.controls['encoding'].setValue(undefined);
    this.form.controls['encoding'].clearValidators();
    this.form.controls['encoding'].updateValueAndValidity();
  }

  public onGetSummary(): void {
    if (this.form.invalid && !this.file) return;
    this.clearErrorMessage();
    this.isLoadingSummary = true;
    const formValue = this.form.value;
    const formData = new FormData();
          formData.append('file', this.file ?? '');
          formData.append('file_type', formValue['doc_type'].type ?? '');
          formData.append('decode_type', formValue['encode'] ?? '');
    this.httpClientService
      .post(`${ environment.apiURL }/api/summary_csv/`, formData)
      .pipe(
        first(),
        map(x => x as ResponseMessageGetCsvSummarModel)
      )
      .subscribe({
        next: (res) => {
          this.totalDocument = res.total_document;
          this.totalPayment = res.total_payment;
          this.totalVat = res.vat;
          this.nextStep(2);
          this.isLoadingSummary = false;
        },
        error: (err) => {
          console.error(err);
          this.nextStep(2);
          this.errorMessage = err.error;
          this.isError = true;
          this.isLoadingSummary = false;
        }
      })
  }

  public onGenerate(): void {
    this.clearErrorMessage();
    this.isLoadingGenerate = true;
    const formValue = this.form.value;
    const formData = new FormData();
          formData.append('csv_file', this.file ?? '');
          formData.append('document_type', formValue['doc_type'].type ?? '');
          formData.append('decode_type', formValue['encode'] ?? '');
    this.httpClientService
      .post(`${ environment.apiURL }/api/tax_imports/`, formData)
      .pipe(
        map(x => x as ResponseMessageGenerateXmlOrPdfA3Model)
      )
      .subscribe({
        next: (res) => {
          this.startUploadStatusInterval(res.background_job_id);
          this.taxImportId = res.id;
          this.isLoadingGenerate = false;
        },
        error: (err) => {
          console.error(err);
          this.errorMessage = 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง';
          if (typeof err === 'string' && err.includes('pending to process or processing')) {
            this.errorMessage = 'NEW-TRANSLATE.COMMON.PENDING-TO-PROCESS-OR-PROCESSING';
          }
          this.isError = true;
          this.isLoadingGenerate = false;
        }
      })
  }

  private startUploadStatusInterval(backgroundJobId: number) {
    this.isLoadingUploadStatus = true;
    return interval(5000)
      .pipe(
        takeWhile(x => this.isLoadingUploadStatus),
        switchMap(() => this.getUploadStatus(backgroundJobId))
      )
      .subscribe({
        next: (res) => {
          this.handleUploadStatusResponse(res);
        },
        error: (err) => {
          console.error(err);
          this.isLoadingUploadStatus = false;
        }
      })
  }

  private getUploadStatus(backgroundJobId: number): Observable<ResponseMessageBackgroundJobStatusModel> {
    return this.httpClientService
      .get(`${ environment.apiURL }/api/background_jobs/${ backgroundJobId }/`)
      .pipe(
        map(x => x as ResponseMessageBackgroundJobStatusModel)
      );
  }


  private handleUploadStatusResponse(res: ResponseMessageBackgroundJobStatusModel): void {
    this.uploadProgress = Number(res.progress);
    if (res.status === 'success') {
      this.isLoadingUploadStatus = false;
      this.isUploadSuccess = true;
      
    } else if (res.status === 'failed') {
      this.isLoadingUploadStatus = false;
      this.isError = true;
      this.handleJobFailure(res);
    }
  }

  private handleJobFailure(res: ResponseMessageBackgroundJobStatusModel) {
    if (Array.isArray(res.result)) {
      this.errorsMessage = res.result;
    } else {
      this.errorMessage = res.result.message;
    }
  }

  private clearErrorMessage(): void {
    this.errorMessage = undefined;
    this.errorsMessage = [];
    this.isError = false;
  }

  public createANewFile(): void {
    this.step = 0;
    this.form.controls['doc_type'].setValue(undefined);
    this.onClear();
  }

}