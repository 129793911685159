import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ApiService } from '../../../service/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../service/notification.service';
import { UtilityService } from '../../../service/utility.service';

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrl: './profile-password.component.scss'
})
export class ProfilePasswordComponent implements OnInit {

  public oldPassword: string = '';
  public newPassword: string = '';
  public confirmPassword: string = '';
  public errorMessage: string | null = null;
  public newPasswordInputType = true;
  public oldPasswordInputType = true;
  public recaptchaSiteKey = environment['recaptChaSiteKey'];
  public recaptchaToken = null;
  public isLoading = false;

  @ViewChild('recaptchaRef') public recaptchaRef: RecaptchaComponent | undefined;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit(){
    this.initialSetting();
  }

  initialSetting(): void {
    this.translateService
      .onLangChange
      .subscribe(
        (res) => {
          this.errorMessage = null;
        }
      );
  }

  toggleNewInputType(): void {
    this.newPasswordInputType = !this.newPasswordInputType;
  }

  toggleOldInputType(): void {
    this.oldPasswordInputType = !this.oldPasswordInputType;
  }

  hasRecaptchaBeenValidated(): boolean {
    return Boolean(this.recaptchaToken);
  }

  executeRecaptcha(): void | undefined {
    return this.recaptchaRef?.execute();
  }

  resolve(token: any): void {
    this.recaptchaToken = token;
    if(token){
      this.changePassword()
    }
  }

  submitclick(): void {
    this.isLoading = true;
    if (!this.hasRecaptchaBeenValidated()) {
      this.executeRecaptcha();
    } else {
      this.changePassword();
    }
  }


  changePassword(): void {
    this.errorMessage = null;

    if (this.oldPassword.length == 0) {
      this.errorMessage = 'PROFILE.PASSWORD.PLS-FILL-IN-OLD-PASSWORD';
      this.isLoading = false;
    } else if (this.newPassword.length == 0) {
      this.errorMessage = 'PROFILE.PASSWORD.PLS-FILL-IN-PASSWORD';
      this.isLoading = false;
    } else if (this.confirmPassword !== this.newPassword) {
      this.errorMessage = 'PROFILE.PASSWORD.PASSWORD-DOES-NOT-MATCH';
      this.isLoading = false;
    } else {
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        recaptchaToken : this.recaptchaToken,
      }

      this.apiService
        .updatePassword(data)
        .subscribe({
          next: (res) => {
            this.notificationService.showSuccess(this.translateService.instant('TAX-B2P.SUCCESS'))
            this.utilityService.setItemInLocalStorage('require_to_change_password' , false);
            this.isLoading = false;
            this.router.navigate([''])
          },
          error: (err) => {
            this.errorMessage = this.utilityService.getErrorString(err.error)
            if(this.errorMessage.includes('invalid token')){
              this.recaptchaToken = null;
              this.recaptchaRef?.reset();
            }
            this.isLoading = false;
          }
        });
    }
  }

}

