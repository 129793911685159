<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.BULK-TAX-REPORT.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="bulk-tax-report-container">
  <div class="criteria-search-container">
    <app-filter-header
      [title]="!isViewerImpAndHelp ? 'NEW-TRANSLATE.COMMON.GENERATE' : undefined"
      [submitButtonName]="!isViewerImpAndHelp ? 'NEW-TRANSLATE.COMMON.GENERATE' : undefined"
      [clearButtonName]="!isViewerImpAndHelp ? 'NEW-TRANSLATE.COMMON.CLEAR' : undefined"
      [loading]="isLoading || isLoadingRows || isLoadingOther || isLoadingGenerate"
      (submit)="onSubmit()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther || isLoadingGenerate"
                (onChange)="filterBranches($event.value)"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_branch_id"
                selectId="branchSelect"
                [options]="branches"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther || isLoadingGenerate"
                [placeholder]="'NEW-TRANSLATE.COMMON.BRANCH' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="branchSelect">{{ 'NEW-TRANSLATE.COMMON.BRANCH' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="rd_document_type"
                [loading]="isLoading || isLoadingRows || isLoadingOther || isLoadingGenerate"
                [options]="documentType"
                optionValue="type"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="documentTypeSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="documentTypeSelect" class="required-field">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
             <app-date-range-picker
              formControlName="docu_date_period"
              label="NEW-TRANSLATE.COMMON.DOCUMENT-DATE"
              [maxRange]="maxRange"
              [ngClass]="{ 'is-invalid': submitted && form.controls['docu_date_period'].errors }"
              [isLoading]="isLoading || isLoadingRows || isLoadingOther || isLoadingGenerate"
             ></app-date-range-picker>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther || isLoadingGenerate"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    />
    <div class="datatble-description-conrainer pt-3">
      <h5 class="mb-0">{{ 'NEW-TRANSLATE.COMMON.READY-TO-DOWNLOAD' | translate }}</h5>
    </div>
    <app-datatables
      [collectionSize]="collectionSize"
      [columns]="columns"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [limitRow]="limitRow"
      [pages]="pages"
      [rows]="rows"      
      tableId="bulkTaxReportDatatable"
      (onRowActionEvent)="onRowActionEvent($event)"
    />
  </div>
</div>
