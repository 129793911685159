<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.MONITORING.TITLE' | translate }}</h3>
    </div>
    <form [formGroup]="form" class="form-wrapper float-right">
      <div class="row g-2">
        @if (isBCMStaff) {
          <div class="company-dropdown">
            <p-dropdown
              class="dv-dropdown"
              formControlName="corporate_id"
              selectId="companySelect"
              [options]="companies"
              optionValue="id"
              [loading]="isLoading || isLoadingChart"
              (onChange)="loadMonitoring()"
              [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
            >
              <ng-template let-item pTemplate="selectedItem">
                <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
              </ng-template>
            </p-dropdown>
          </div>
        }
        <div class="datepicker-dropdown">
          <p-calendar
            class="form-control date-picker-custom datepicker-style-custom date-picker-without-label"
            dateFormat="dd/mm/yy"
            [disabled]="isLoading || isLoadingChart"
            formControlName="date"
            inputId="monitoringDatePicker"
            [iconDisplay]="'input'"
            [maxDate]="currentDate"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-DATE' | translate"
            [readonlyInput]="true"
            [showIcon]="true"
            (onSelect)="loadMonitoring()"
          >
            <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
              <div class="date-picker-icon-custom">
                <fa-icon
                  [icon]="['far', 'calendar']"
                  size="xl"
                  (click)="clickCallBack($event)"
                ></fa-icon>
              </div>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="monitoring-container">
  <div class="chart-container">
    <canvas
      [ngClass]="[ isLoading ? 'd-none' : 'd-block' ]"
      data-testid="chartOfMonitoring"
      id="chartOfMonitoring"
    >{{ chartOfMonitoring }}</canvas>
    @if (isLoading) {
      <div class="loading-container">
        <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="2xl"></fa-icon>
      </div>
    }
  </div>
</div>