import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Observable, Subject, first, map, takeUntil, zip } from 'rxjs';
import { LanguageType, LookupModel, ResponseMessageCompanyModel } from '../../shared/interfaces';
import { MONTHS } from '../../shared/services/shared.constant';
import { Chart, ChartOptions } from 'chart.js';
import { color as ChartColor } from 'chart.js/helpers';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpClientService } from '../../service/http-client.service';
import { HandleTranslateService, HandleModalService, UtilitiesService } from '../../shared/services';
import { environment } from '../../../environments/environment';
import { MonitoringModel, PayloadMonitoringModel, ResponseMessageMonitoringModel } from './monitoring.interface';
import moment from 'moment';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrl: './monitoring.component.scss'
})
export class MonitoringComponent implements AfterViewInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public months: LookupModel[] = [ ...MONTHS ];

  public currentDate: Date = new Date();

  public chartOfMonitoring: Chart<'line'> | undefined;
  private chartData: MonitoringModel | undefined;

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    date: new FormControl(this.currentDate, [ Validators.required ])
  });

  public isBCMStaff: boolean = false;

  public isLoading: boolean = false;
  public isLoadingChart: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService
  ) {
    this.checkPermission();
    this.settingRolePermission();
    this.subscribeToServices();
  }

  ngAfterViewInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chartOfMonitoring?.destroy();
  }

  private subscribeToServices(): void {
    this.handleTranslateService
      .language
      ?.pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(x => {
        this.language = x;
        if (this.chartOfMonitoring) {
          this.settingChart();
        }
      });
  }

  private checkPermission(): void {
    if (this.authenticationService.isSCBBCMManageUser() || this.authenticationService.isViewerOperation()) {
      this.router.navigate(['/user-management']);
    } else if (!this.authenticationService.isMonitoringPermission()) {
      this.router.navigate(['/']);
    }
  }

  private settingRolePermission(): void {
    this.isBCMStaff = this.authenticationService.isSCBBCM();
  }

  private initialSetting(): void {
    this.settingChart();
    this.isLoading = true;
    zip(
      this.loadCompany()
    )
    .pipe()
    .subscribe({
      next: ([ companies ]) => {
        this.companies = companies;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
        } else {
          this.companies.unshift(this.allSelect);
        }
        this.loadMonitoring();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    });
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        first(),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [...newRes.results].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  public loadMonitoring(): void {
    this.isLoadingChart = true;
    const formValue = this.form.value;
    const params: PayloadMonitoringModel = {
      company_id: formValue['corporate_id'] ?? '',
      date: moment(formValue['date']).format('YYYY-MM-DD') ?? ''
    };
    this.httpClientService
      .get(`${ environment.apiURL }/api/dashboard_sign_counter_minute/`, params)
      .pipe(
        first(),
        map(x => x as ResponseMessageMonitoringModel)
      )
      .subscribe({
        next: (res) => {
          this.chartData = res;
          this.settingChart();
          this.isLoadingChart = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingChart = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }


  private settingChart(): void {
    const color = ChartColor;
    const data = {
      labels: this.chartData?.signing_counter_minute.label ?? [],
      datasets: [
        {
          label: this.handleTranslateService.translate('NEW-TRANSLATE.COMMON.TRANSACTION-COUNT'),
          data: this.chartData?.signing_counter_minute.data ?? [],
          backgroundColor: color('rgb(91,36,131)').alpha(0.5).rgbString(),
          borderColor: 'rgb(91,36,131)'
        }
      ]
    };
    if (!this.chartOfMonitoring) {
      this.chartOfMonitoring = new Chart('chartOfMonitoring', {
        type: 'line',
        data: data,
        options: this.getChartOptions()
      });
    } else {
      this.chartOfMonitoring.data = data;
      this.chartOfMonitoring.options = this.getChartOptions();
      this.chartOfMonitoring.update();
    }
  }

  private getChartOptions(): ChartOptions<'line'> {
    const options: ChartOptions<'line'> = {
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: 'index',
        intersect: true
      },
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: (event) => {
              return moment(event[0].label, "MMM D, YYYY, h:mm:ss a").format('HH:mm');
            }
         }
        },
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            parser: 'HH:mm',
            unit: 'hour',
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm'
            },
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value: any) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }
      }
    };
    return options;
  }

}
