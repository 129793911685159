import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';
import { Observable, Subject, first, map, of, takeUntil, zip } from 'rxjs';
import { ApiService } from '../../service/api.service';
import { HttpClientService } from '../../service/http-client.service';
import { DocumentTypeModel, LanguageType, LookupModel, ResponseMessageCompanyModel } from '../../shared/interfaces';
import { HandleTranslateService, HandleModalService, UtilitiesService } from '../../shared/services';
import { DOCUMENT_TYPE, MONTHS } from '../../shared/services/shared.constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { PayloadDashboardModel, ResponseMessageDashboardModel } from './dashboard.interface';
import { Chart, ChartOptions, TooltipItem } from 'chart.js';
import { color as ChartColor } from 'chart.js/helpers';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements AfterViewInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public years: number[] = [];
  public months: LookupModel[] = [ ...MONTHS ];
  public documentTypeList: DocumentTypeModel[] = [ ...DOCUMENT_TYPE ];

  private currentDate: Date = new Date();

  public chartOfDashboard: Chart<'bar'> | undefined;

  private taxInvoiceTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private receiptTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private creditNoteTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private debitNoteTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private receiptTaxInvoiceTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private deliveryOrderTaxInvoiceTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private invoiceTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
  private invoiceTaxInvoiceTotal: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    year: new FormControl(this.currentDate.getFullYear(), [ Validators.required ])
  });

  public isBCMStaff: boolean = false;

  public isLoading: boolean = false;
  public isLoadingChart: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService
  ) {
    this.checkPermission();
    this.settingRolePermission();
    this.subscribeToServices();
  }

  ngAfterViewInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chartOfDashboard?.destroy();
  }

  private subscribeToServices(): void {
    this.handleTranslateService
      .language
      ?.pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(x => {
        this.language = x;
        if (this.chartOfDashboard) {
          this.settingChart();
        }
      });
  }

  private checkPermission(): void {
    if (this.authenticationService.isSCBBCMManageUser() || this.authenticationService.isViewerOperation()) {
      this.router.navigate(['/user-management']);
    } else if (!this.authenticationService.isDashbaordPermission()) {
      this.router.navigate(['/']);
    }
  }

  private settingRolePermission(): void {
    this.isBCMStaff = this.authenticationService.isSCBBCM();
  }

  private initialSetting(): void {
    this.settingChart();
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadYear()
    )
    .pipe()
    .subscribe({
      next: ([ companies, years ]) => {
        this.companies = companies;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
        } else {
          this.companies.unshift(this.allSelect);
        }
        this.years = years;
        this.loadDashboard();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    });
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        first(),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [...newRes.results].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  private loadYear(): Observable<number[]> {
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return of(years);
  }

  public loadDashboard(): void {
    this.isLoadingChart = true;
    const formValue = this.form.value;
    const params: PayloadDashboardModel = {
      company_id: formValue['corporate_id'] ?? '',
      year: formValue['year'] ?? ''
    };
    this.httpClientService
      .get(`${ environment.apiURL }/api/dashboard_monthly/`, params)
      .pipe(
        first(),
        map(x => x as ResponseMessageDashboardModel)
      )
      .subscribe({
        next: (res) => {
          this.taxInvoiceTotal = res.signing_counter.tax_invoice ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.receiptTotal = res.signing_counter.receipt ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.creditNoteTotal = res.signing_counter.credit_note ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.debitNoteTotal = res.signing_counter.debit_note ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.receiptTaxInvoiceTotal = res.signing_counter.receipt_tax_invoice ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.deliveryOrderTaxInvoiceTotal = res.signing_counter.delivery_order_tax_invoice ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.invoiceTotal = res.signing_counter.invoice ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.invoiceTaxInvoiceTotal = res.signing_counter.invoice_tax_invoice ?? [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
          this.settingChart();
          this.isLoadingChart = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingChart = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private settingChart(): void {
    const color = ChartColor;
    const data = {
      labels: [ ...this.months ].map(x => this.language === 'th' ? x.name_th : x.name_en),
      datasets: [
        {
          label: this.language === 'th' ? this.documentTypeList[0].name_th : this.documentTypeList[0].name_en,
          backgroundColor: color('#d5daeb').rgbString(),
          borderColor: 'rgb(91,36,131)',
          data: this.taxInvoiceTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[1].name_th : this.documentTypeList[1].name_en,
          backgroundColor: color('#aae0e0').rgbString(),
          borderColor: 'rgb(75, 192, 192)',
          data: this.receiptTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[2].name_th : this.documentTypeList[2].name_en,
          backgroundColor: color('#ffd1a5').rgbString(),
          borderColor: 'rgb(255, 183, 115)',
          data: this.creditNoteTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[3].name_th : this.documentTypeList[3].name_en,
          backgroundColor: color('#FFB5C3').rgbString(),
          borderColor: 'rgb(255, 99, 132)',
          data: this.debitNoteTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[4].name_th : this.documentTypeList[4].name_en,
          backgroundColor: color('rgb(247, 208, 56)').alpha(0.9).rgbString(),
          borderColor: 'rgb(247, 208, 56)',
          data: this.receiptTaxInvoiceTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[5].name_th : this.documentTypeList[5].name_en,
          backgroundColor: color('rgb(73, 218, 154)').alpha(0.9).rgbString(),
          borderColor: 'rgb(73, 218, 154)',
          data: this.deliveryOrderTaxInvoiceTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[6].name_th : this.documentTypeList[6].name_en,
          backgroundColor: color('rgb(251, 150, 150)').alpha(0.9).rgbString(),
          borderColor: 'rgb(251, 150, 150)',
          data: this.invoiceTotal
        },
        {
          label: this.language === 'th' ? this.documentTypeList[7].name_th : this.documentTypeList[7].name_en,
          backgroundColor: color('rgb(206, 185, 252)').alpha(0.9).rgbString(),
          borderColor: 'rgb(206, 185, 252)',
          data: this.invoiceTaxInvoiceTotal
        }
      ]
    };
    if (!this.chartOfDashboard) {
      this.chartOfDashboard = new Chart('chartOfDashboard', {
        type: 'bar',
        data: data,
        options: this.getChartOptions()
      });
    } else {
      this.chartOfDashboard.data = data;
      this.chartOfDashboard.options = this.getChartOptions();
      this.chartOfDashboard.update();
    }
  }

  private getChartOptions(): ChartOptions<'bar'> {
    const unitName = this.handleTranslateService.translate('NEW-TRANSLATE.COMMON.TIMES');
    const totalName = this.handleTranslateService.translate('NEW-TRANSLATE.COMMON.TOTAL');
    let total = 0;
    const options: ChartOptions<'bar'> = {
      plugins: {
        tooltip: {
          filter: (tooltipItem: TooltipItem<'bar'>, tooltipIndex: number, array: TooltipItem<'bar'>[]) => {
            const showLabel = !array.every(x => x.dataset.data[tooltipItem.dataIndex] === 0);
            return showLabel;
          },
          mode: 'index',
          intersect: false,
          callbacks: {
            afterTitle: () => {
              total = 0;
            },
            label: (context) => {
              
              const value = this.getTotalFromDataset(context.dataset.data[context.dataIndex]);
              let label = (context.dataset.label ?? context.label ?? '') + this.getTooltipLabel(value, unitName);
              total += value;
              return label;
            },
            footer: (context) => {
              const text = [ totalName + ' : ' + total.toString() + ' ' + unitName +
                ((total >= 2 && unitName === 'Time' && this.language !== 'th') ? 's' : '')];
              return context && context.length > 0 ? text : '';
            }

          }
        },
        legend: {
          position: 'top'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
            stacked: true,
        },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            callback: (value: any) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }
      },
    };
    return options;
  }

  private getTotalFromDataset(total: number | number[] | null): number {
    if (typeof total === 'number') {
      return total;
    } else if (Array.isArray(total)) {
      return total.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    } else {
      return 0;
    }
  }

  private getTooltipLabel(total: number, unitName: string): string {
    return ' : ' + total.toString() + ' ' + unitName + (total >= 2 && this.language !== 'th' ? 's' : '');
  }

}
