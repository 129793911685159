<div class="header">
  <h3 id="pageTitle">{{ 'DATA-PURGE.TITLE' | translate }}</h3>
  <h5>{{ 'CHART.TOTAL' | translate }} {{ totalItems | number }} {{ 'TEXT.FILE'| translate }}</h5>
</div>

<div class="row">
  <div class="col-md-8">
    <div class="box-filter position-relative z-2">
      <app-filter-header [loading]="loading" (submit)="search()" (clear)="clearSearch()"></app-filter-header>

      <div class="row">
        <div class="col-md-6 p-5px">
        </div>

        <div class="col-md-12 p-5px">
          <div class="dropdown-label-group">
            <p-dropdown class="dv-dropdown" [options]="timeList" [(ngModel)]="selectedTime" [style]="{'z-index': 3}">
            </p-dropdown>
            <label for="company">{{'FILTER.PERIOD-OF-TIME' | translate}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="box-parent position-relative z-1">
      <app-custom-pagination
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        [totalItems]="totalItems"
        (pageChange)="pageChanged()"
        (optionChangeValue)="setPageSide($event)"
      >
        <div class="text-right">
          <button type="button" class="btn btn-dv btn-wide" (click)="purgeFile()" [disabled]="!checkSelectedList()">
            {{'DATA-PURGE.PURGE-FILE' | translate}}
          </button>
        </div>
      </app-custom-pagination>


      <table class="table text-center mt-3" aria-describedby="mydesc">
        <thead>
          <tr>
            <th *ngIf="!isViewer()" class="v-middle">
              <p-checkbox class="dv-checkbox" binary="true" (onChange)="checkAll($event)">
              </p-checkbox>
            </th>
            <th class="v-middle text-left">{{ 'TABLE.FILE-NAME' | translate }}</th>
            <th class="v-middle">{{ 'TABLE.IMPORT-DATE' | translate }}</th>
            <th class="v-middle">{{ 'TABLE.DUE-DATE' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of fileList">
            <td *ngIf="!isViewer()">
              <p-checkbox class="dv-checkbox" binary="true" [(ngModel)]="file.is_purged_selected"
                (onChange)="updateStatus(file)"></p-checkbox>
            </td>
            <td class="text-left">{{ file.csv_name || 'ไม่ระบุชื่อ' }}</td>
            <td>{{ file.created_at|date:'dd/MM/yyyy' }}</td>
            <td>{{ file.due_date }}</td>
          </tr>
          <tr *ngIf="!fileList.length">
            <td colspan="4" class="text-center">{{ 'DATA-PURGE.NO-FILE-LEFT' | translate}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-4">
    <div class="box-filter position-relative z-2">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h5 class="mb-0">{{ 'DATA-PURGE.STORAGE' | translate }}</h5>
        </div>
        <div class="col-6">
          <div class="dropdown-label-group">
            <p-dropdown class="dv-dropdown" [options]="yearList" (onChange)="filterStorageByYear()"
              [(ngModel)]="selectedYear" [style]="{'z-index': 3}">
            </p-dropdown>
            <label for="time">Year</label>
          </div>
        </div>
      </div>
      <div *ngIf="purgeList.length" class="text-right m-t-10">
        <a class="btn btn-dv btn-wide" [routerLink]="['/purge', 'result']">
            {{ 'DATA-PURGE.PENDING' | translate}}
        </a>
      </div>
    </div>
    <div class="box-parent position-relative z-1" *ngIf="storageList.length">
      <div class="form-group row align-items-center" *ngFor="let data of storageList">
        <label for="staticEmail" class="col-2 col-form-label">{{ data.month }}</label>
        <div class="offset-1 col-9 text-right">
          {{ data.file_size }}
        </div>
      </div>
    </div>
    <div class="box-parent position-relative z-1" *ngIf="!storageList.length">
      <div class="text-center">
        {{ 'DATA-PURGE.NO-STORAGE-DATA' | translate }}
      </div>
    </div>
  </div>
</div>
