import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { get as lodashGet } from 'lodash';
import { API_CONSTANT } from './api.constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {

  }

  login(username: string, password: string): Observable<Object> {
    return this.httpClient.post(API_CONSTANT.login, {
      username: username,
      password: password
    });
  }

  get_max_failed_login_attempted(): Observable<Object> {
    return this.httpClient.get(API_CONSTANT.get_max_failed_login_attempted)
  }

  loggingLogin(data: any): Observable<Object> {
    return this.httpClient.post(API_CONSTANT.logging_login , data);
  }

  requestOtp(email: string): Observable<Object> {
    return this.httpClient.post(API_CONSTANT.request_otp, {
      email: email,
    });
  }

  validateOTP(password: string): Observable<Object> {
    return this.httpClient.post(API_CONSTANT.validate_otp, {
      password: password,
    });
  }

  get_login_attempted_left(username: string): Observable<Object> {
    return this.httpClient.post(API_CONSTANT.get_login_attempted_left , {username : username})
  }

  updateUserInfo(user: any): void {
    localStorage.setItem('userID', lodashGet(user, 'id'));
    localStorage.setItem('username', lodashGet(user, 'username'));
    localStorage.setItem('firstName', lodashGet(user, 'first_name'));
    localStorage.setItem('lastLame', lodashGet(user, 'last_name'));
    localStorage.setItem('isSuperUser', lodashGet(user, 'is_superuser'));
    localStorage.setItem('group', lodashGet(user, 'group'));
    localStorage.setItem('require_to_change_password' , user['require_to_change_password'] || false);
    localStorage.setItem('company_tax_id', lodashGet(user, 'company_tax_id'));
    localStorage.setItem('see_keyin_page', lodashGet(user, 'see_keyin_page') || false);
    localStorage.setItem('service_provider_option', lodashGet(user, 'service_provider_option'));
    localStorage.setItem('see_setting_page', lodashGet(user, 'see_setting_page') || false);
  }

  reloadUserInfo(): void {
    if (this.isLoggedIn()) {
      this.apiService.getMe().subscribe(
        (user: any) => {
          this.updateUserInfo(user)
        }
      );
    }
  }

  getUserId(): string | null {
    return localStorage.getItem('userID');
  }

  requireToChangePassword(): boolean {
    return localStorage.getItem('require_to_change_password') === 'true';
  }

  isLoggedIn(): boolean {
    return localStorage.getItem('currentUser') !== null;
  }

  isAdmin(): boolean {
    return localStorage.getItem('group') === 'Admin' || localStorage.getItem('isSuperUser') === 'true';
  }

  isAccountant(): boolean {
    return localStorage.getItem('group') === 'Maker(Admin)';
  }

  public isAdvanceServiceProvider(): boolean {
    const serviceProviderOption = localStorage.getItem('service_provider_option')
    return serviceProviderOption === '1' ||  serviceProviderOption === '2';
  }

  public isSettingManual(): boolean {
    const serviceProviderOption = localStorage.getItem('service_provider_option');
    return serviceProviderOption === '1' || serviceProviderOption === '0'
  }

  public isKeyinPermission(): boolean {
    return this.isAccountant() && (localStorage.getItem('see_keyin_page')?.toLowerCase() === 'true');
  }

  public isSettingPermission(): boolean {
    return this.isViewerImpAndHelp() || (this.isIT() && (localStorage.getItem('see_setting_page')?.toLowerCase() === 'true'));
  }

  public isSubmitToRdPermission(): boolean {
    return this.isViewerImpAndHelp() || (this.isAccountantAdmin() && this.isAdvanceServiceProvider());
  }

  public isRdSummaryPermission(): boolean {
    return this.isViewerImpAndHelp() || (this.isAccountantAdmin() && this.isAdvanceServiceProvider());
  }

  public isSalesTaxReportPermission(): boolean {
    return this.isUIH() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp();
  }

  public isDocumentSearchPermission(): boolean {
    return this.isUIH() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp();
  }

  public isEmailDashboardPermission(): boolean {
    return this.isAdmin() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp()
  }

  public isRevenueDocumentPermission(): boolean {
    return this.isUIH() || (this.isAccountantAdmin() && !this.isAdvanceServiceProvider()) || this.isViewerImpAndHelp()
  }

  public isFeeEnginePermission(): boolean {
    return this.isSCBBCMViewer();
  }

  public isDocumentUploadPermission(): boolean {
    return this.isAccountant() && !this.isSCIUser();
  }

  public isBulkTaxReportPermission(): boolean {
    return this.isUIH() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp();
  }

  public isViewerImpAndHelp(): boolean {
    return localStorage.getItem('group') === 'Viewer (Implementation and Helpdesk)';
  }

  public isAnnouncementPermission(): boolean {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  public isDashbaordPermission(): boolean {
    return this.isAdmin() || this.isUIH() || this.isAccountantAdmin() || this.isIT() || this.isAccountant() || this.isViewerImpAndHelp();
  }

  public isMonitoringPermission(): boolean {
    return this.isAdmin() || this.isUIH() || this.isIT() || this.isAccountant() || this.isViewerImpAndHelp();
  }

  isAccountantAdmin(): boolean {
    return localStorage.getItem('group') === 'Signer(Accounting Manager)';
  }

  isIT(): boolean {
    return localStorage.getItem('group') === 'Viewer(IT)';
  }

  isCallCenter(): boolean {
    return localStorage.getItem('group') === 'Call Center';
  }

  isUIH(): boolean {
    return localStorage.getItem('group') === 'UIH';
  }

  isSuperMaker(): boolean {
    return localStorage.getItem('group') === 'Super_maker';
  }

  isMaker(): boolean {
    return localStorage.getItem('group') === 'Maker';
  }

  isSuperChecker(): boolean {
    return localStorage.getItem('group') === 'Super_checker';
  }

  isChecker(): boolean {
    return localStorage.getItem('group') === 'Checker';
  }

  isViewerOperation(): boolean {
    return localStorage.getItem('group') === 'Viewer (Operation)';
  }

  isSCBBCM(): boolean {
    return this.isSuperMaker() || this.isMaker() || this.isSuperChecker() || this.isChecker()
      || this.isViewerOperation() || this.isViewerImpAndHelp();
  }

  isSCBBCMViewer(): boolean {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  isSCBBCMManageUser(): boolean {
    return this.isSuperMaker() || this.isMaker() || this.isSuperChecker() || this.isChecker()
  }

  isSCIUser(): boolean {
    return localStorage.getItem('company_tax_id') === '0105531096240';
  }

}
