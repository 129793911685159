import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequireResetPasswordGuard } from '../../service/guards/require-reset-password.guard';
import { BillingListComponent } from './billing-list/billing-list.component';

export const routes: Routes = [
  {
    path: 'billing',
    canActivate : [ RequireResetPasswordGuard ],
    component: BillingListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
