import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { get as lodashGet } from 'lodash';
import { Observable } from 'rxjs';
import { DefaultLanguage } from '../shared/services';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  checkError(apiFunction: Observable<any>): any {
    return new Observable((observer) => {
      apiFunction
      .subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        },
        error: (err) => {
          if (
            lodashGet(err, 'error.detail') === 'Signature has expired.' ||
            lodashGet(err, 'error.detail') === 'Error decoding signature.' ||
            lodashGet(err, 'error.detail') === 'Invalid signature.' ||
            lodashGet(err, 'error.detail') === 'User account is disabled.' ||
            lodashGet(err, 'error.detail') === 'บัญชีผู้ใช้ถูกระงับ'
          ) {
            localStorage.clear();
            this.router.navigate(['login', 'ล็อกอินหมดอายุ โปรดล็อกอินอีกครั้ง'], {skipLocationChange: true});
          }
          observer.error(err);
        }
      });
    });
  }

  private getHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('currentUser')).set('Accept-Language', DefaultLanguage() );
  }

  get(url: string, params?: any): Observable<Object> {
    return this.checkError(this.httpClient.get(url, {params: params, headers: this.getHeader()}));
  }

  getBlob(url: string, params?: any): Observable<Object> {
    return this.checkError(this.httpClient.get(url, {params: params, headers: this.getHeader(), responseType: 'blob'}));
  }

  post(url: string, data: any, params?: any): Observable<Object> {
    return this.checkError(this.httpClient.post(url, data, {params: params, headers: this.getHeader()}));
  }

  postBlob(url: string, data: any): Observable<Object> {
    return this.checkError(this.httpClient.post(url, data, {headers: this.getHeader(), responseType: 'blob'}));
  }

  put(url: string, data: any, params?: any): Observable<Object> {
    return this.checkError(this.httpClient.put(url, data, {params: params, headers: this.getHeader()}));
  }

  patch(url: string, data: any): Observable<Object> {
    return this.checkError(this.httpClient.patch(url, data, {headers: this.getHeader()}));
  }

  delete(url: string): Observable<Object> {
    return this.checkError(this.httpClient.delete(url, {headers: this.getHeader()}));
  }

  createDownloadFile(blob: Blob, filename: string): void {
    const navigator = window.navigator as any;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const element = document.createElement('a');
      document.body.appendChild(element);
      const url = window.URL.createObjectURL(blob);
      element.setAttribute('href', url);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      element.click();

      document.body.removeChild(element);
    }
  }

}
