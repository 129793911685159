@if (form) {
  <div class="keyin-section-3-container box-parent section-box mb-2">
    <h5 class="section-title">{{ 'NEW-TRANSLATE.COMMON.BUYER-DETAIL' | translate }}</h5>
    <form [formGroup]="form" class="form-wrapper">
      <div class="buyer-detail-wrapper">
        <label class="form-label required-field">{{ 'NEW-TRANSLATE.COMMON.BUYER-TYPE' | translate }}</label>
        <div class="row g-2">
          <div class="col-12 col-md-6">
            <div class="mb-2 d-flex gap-3 flex-wrap">
              @for (buyerType of buyerTypes; track buyerType.value) {
                <div class="d-flex align-items-center">
                  <p-radioButton 
                    formControlName="buyer_type"
                    [inputId]="'buyerType' + buyerType.value + 'Radio'"
                    [value]="buyerType.value"
                    (onClick)="onChangeBuyerType($event)"
                  />
                  <label [for]="'buyerType' + buyerType.value + 'Radio'" class="ms-2">
                    {{ buyerType.label | translate }}
                  </label>
                </div>
              }
            </div>
          </div>
          <div *ngIf="form.controls['buyer_type'].value !== 3" class="col-6 d-none d-md-block">
            <label class="form-label ps-0 ps-md-3">{{ 'NEW-TRANSLATE.COMMON.BUYER-ADDRESS' | translate }}</label>
          </div>
        </div>
        <div class="row mb-3 g-2">
          <div class="col-12 col-md-6">
            <div class="row mb-3 g-2">
              <ng-container [ngSwitch]="form.controls['buyer_type'].value">
                <ng-container *ngSwitchCase="2">
                  <div class="col-12">
                    <label class="form-label required-field" for="buyerNameInput">{{ 'NEW-TRANSLATE.KEYIN.BUYER-NAME-OR-COMPANY-NAME' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="buyerNameInput"
                      formControlName="buyer_name"
                      id="buyerNameInput"
                      [maxlength]="validators.buyer_detail.buyer_name.maxLength"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_name'].errors }"
                    />
                  </div>
                  <div class="col-12">
                    <label class="form-label required-field" for="taxIdInput">{{ 'NEW-TRANSLATE.COMMON.TAX-ID' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="taxIdInput"
                      formControlName="buyer_taxid"
                      id="taxIdInput"
                      mask="0*"
                      [maxlength]="validators.buyer_detail.buyer_taxid.juristic.maxLength"
                      [maxlength]="validators.buyer_detail.buyer_taxid.juristic.minLength"
                      [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.ENTER-TAX-ID-13-DIGITS' | translate"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_taxid'].errors }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label required-field" for="buyerBranchCodeInput">{{ 'NEW-TRANSLATE.COMMON.BRANCH-CODE' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="buyerBranchCodeInput"
                      formControlName="buyer_branch_code"
                      id="buyerBranchCodeInput"
                      mask="0*"
                      [maxlength]="validators.buyer_detail.buyer_branch_code.maxLength"
                      [minlength]="validators.buyer_detail.buyer_branch_code.minLength"
                      [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.ENTER-BRANCH-CODE-5-DIGITS' | translate"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_branch_code'].errors }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label required-field" for="buyerBranchNameInput">{{ 'NEW-TRANSLATE.COMMON.BRANCH-NAME' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="buyerBranchNameInput"
                      formControlName="buyer_branch_name"
                      id="buyerBranchNameInput"
                      [maxlength]="validators.buyer_detail.buyer_branch_name.maxLength"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_branch_name'].errors }"
                    />
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                  <div class="col-12">
                    <label class="form-label required-field" for="buyerNameInput">{{ 'NEW-TRANSLATE.COMMON.BUYER-NAME' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="buyerNameInput"
                      formControlName="buyer_name"
                      id="buyerNameInput"
                      [maxlength]="validators.buyer_detail.buyer_name.maxLength"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_name'].errors }"
                    />
                  </div>
                  <div class="col-12">
                    <label class="form-label required-field" for="taxIdInput">{{ 'NEW-TRANSLATE.COMMON.TAX-ID' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="taxIdInput"
                      formControlName="buyer_taxid"
                      id="taxIdInput"
                      [maxlength]="validators.buyer_detail.buyer_taxid.individual.maxLength"
                      [minlength]="validators.buyer_detail.buyer_taxid.individual.minLength"
                      [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.ENTER-TAX-ID-13-DIGITS' | translate"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_taxid'].errors }"
                    />
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                  <div class="col-12">
                    <label class="form-label required-field" for="buyerNameInput">{{ 'NEW-TRANSLATE.COMMON.BUYER-NAME' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="buyerNameInput"
                      formControlName="buyer_name"
                      id="buyerNameInput"
                      [maxlength]="validators.buyer_detail.buyer_name.maxLength"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_name'].errors }"
                    />
                  </div>
                  <div class="col-12">
                    <label class="form-label required-field" for="passportIdInput">{{ 'NEW-TRANSLATE.COMMON.TAX-ID-OR-PASSPORT-ID' | translate }}</label>
                    <input
                      autocomplete="off"
                      class="form-control"
                      data-testid="passportIdInput"
                      formControlName="buyer_taxid"
                      id="passportIdInput"
                      [maxlength]="validators.buyer_detail.buyer_taxid.foreigner.maxLength"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && form.controls['buyer_taxid'].errors }"
                    />
                  </div>
                </ng-container>
              </ng-container>
              <div class="col-12">
                <label class="form-label" for="buyerEmailInput">{{ 'NEW-TRANSLATE.COMMON.EMAIL-TO' | translate }}</label>
                <ng-select
                  #select
                  #emailInputTooltip="ngbTooltip"
                  [addTag]="true"
                  [autoClose]="'inside'"
                  (blur)="select.selectTag()"
                  class="form-control ng-select-hide-arrow ng-select-email"
                  data-testid="buyerEmailInput"
                  formControlName="email_to"
                  id="buyerEmailInput"
                  [items]="[]"
                  [isOpen]="false"
                  [multiple]="true" 
                  [selectOnTab]="true"
                  triggers="focus"
                  (ngModelChange)="onChangeEmail($event, emailInputTooltip)"
                  [ngClass]="{ 'is-invalid': submitted && form.controls['email_to'].errors || (form.controls['email_to'].errors && form.controls['email_to'].errors['email']) }"
                  (keydown.space)="$event.preventDefault()"
                  (keyup.space)="$event.preventDefault()"
                  [ngbTooltip]="form.controls['email_to'].value && form.controls['email_to'].value.length > 0 ? '' : ('NEW-TRANSLATE.GUILD.EMAIL-GUILD' | translate)"
                >
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div>
                      <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                      <span class="ng-value-label">{{ item }}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ng-container [ngSwitch]="form.controls['buyer_type'].value">
              <ng-container *ngSwitchCase="1" >
                <label class="form-label d-block d-md-none">{{ 'NEW-TRANSLATE.COMMON.ADDRESS' | translate }}</label>
                <ng-container [ngTemplateOutlet]="citizenEl"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="2" >
                <label class="form-label d-block d-md-none">{{ 'NEW-TRANSLATE.COMMON.ADDRESS' | translate }}</label>
                <ng-container [ngTemplateOutlet]="citizenEl"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="3">
                <label class="form-label required-field">{{ 'NEW-TRANSLATE.COMMON.ADDRESS' | translate }}</label>
                <ng-container [ngTemplateOutlet]="foreignerEl"></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>
}

<ng-template #citizenEl>
  <form class="form-wrapper" [formGroup]="form">
    <div class="row mb-3 g-2">
      <div class="col-12 col-sm-3">
        <label class="form-label required-field" for="houseNumberInput">{{ 'NEW-TRANSLATE.COMMON.HOUSE-NO' | translate }}</label>
        <input
          class="form-control"
          data-testid="houseNumberInput"
          formControlName="house_no"
          id="houseNumberInput"
          [maxlength]="validators.buyer_detail.house_no.maxLength"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && form.controls['house_no'].errors }"
        >
      </div>
      <div class="col-12 col-sm-9">
        <label class="form-label" for="buildingOrApartmentInput">{{ 'NEW-TRANSLATE.COMMON.BUILDING-NAME' | translate }}</label>
        <input
          class="form-control"
          data-testid="buildingOrApartmentInput"
          formControlName="building_name"
          id="buildingOrApartmentInput"
          [maxlength]="validators.buyer_detail.building_name.maxLength"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && form.controls['building_name'].errors }"
        >
      </div>
      <div class="col-6 col-sm-2">
        <label class="form-label" for="floorInput">{{ 'NEW-TRANSLATE.COMMON.FLOOR' | translate }}</label>
        <input
          class="form-control"
          data-testid="floorInput"
          formControlName="floor"
          id="floorInput"
          [maxlength]="validators.buyer_detail.floor.maxLength"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && form.controls['floor'].errors }"
        >
      </div>
      <div class="col-6 col-sm-5">
        <label class="form-label" for="alleyInput">{{ 'NEW-TRANSLATE.COMMON.ALLEY' | translate }}</label>
        <input
          class="form-control"
          data-testid="alleyInput"
          formControlName="alley"
          id="alleyInput"
          [maxlength]="validators.buyer_detail.alley.maxLength"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && form.controls['alley'].errors }"
        >
      </div>
      <div class="col-6 col-sm-5">
        <label class="form-label" for="mooNumberInput">{{ 'NEW-TRANSLATE.COMMON.VILLAGE-NO' | translate }}</label>
        <input
          class="form-control"
          data-testid="mooNumberInput"
          formControlName="moo_no"
          id="mooNumberInput"
          [maxlength]="validators.buyer_detail.moo_no.maxLength"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && form.controls['moo_no'].errors }"
        >
      </div>
      <div class="col-6 cl-sm-6">
        <label class="form-label" for="roadInput">{{ 'NEW-TRANSLATE.COMMON.ROAD' | translate }}</label>
        <input
          class="form-control"
          data-testid="roadInput"
          formControlName="road"
          id="roadInput"
          [maxlength]="validators.buyer_detail.road.maxLength"
          [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.ROAD-EXAMPLE' | translate"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && form.controls['road'].errors }"
        >
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label required-field" for="provinceSelect">{{ 'NEW-TRANSLATE.COMMON.PROVINCE' | translate }}</label>
        <ng-select
          class="form-control"
          [clearable]="false"
          data-testid="provinceSelect"
          formControlName="province"
          id="provinceSelect"
          [items]="provinces"
          [loading]="isLoadingProvince"
          [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-PROVINCE' | translate"
          [searchFn]="customSearchLocationValue"
          (change)="onChangeProvince($event)"
          [ngClass]="{ 'is-invalid': submitted && form.controls['province'].errors  }"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label required-field" for="districtSelect">{{ 'NEW-TRANSLATE.COMMON.DISTRICT' | translate }}</label>
        <ng-select
          class="form-control"
          [clearable]="false"
          data-testid="districtSelect"
          formControlName="district"
          id="districtSelect"
          [items]="districts"
          [loading]="isLoadingDistrict"
          [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-DISTRICT' | translate"
          [readonly]="!form.controls['province'].value || isLoadingDistrict"
          [searchFn]="customSearchLocationValue"
          (change)="onChangeDistrict(form.controls['province'].value ,$event)"
          [ngClass]="{ 'is-invalid': submitted && form.controls['district'].errors  }"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label required-field" for="subdistrictSelect">{{ 'NEW-TRANSLATE.COMMON.SUB-DISTRICT' | translate }}</label>
        <ng-select
          class="form-control"
          [clearable]="false"
          data-testid="subdistrictSelect"
          formControlName="subdistrict"
          id="subdistrictSelect"
          [items]="subdistricts"
          [loading]="isLoadingSubdistrict"
          [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-SUB-DISTRICT' | translate"
          [readonly]="!form.controls['district'].value || isLoadingSubdistrict"
          [searchFn]="customSearchLocationValue"
          (change)="onChangeSubdistrict($event)"
          [ngClass]="{ 'is-invalid': submitted && form.controls['subdistrict'].errors  }"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label required-field" for="postalCodeInput">{{ 'NEW-TRANSLATE.COMMON.POSTAL-CODE' | translate }}</label>
        <input
          class="form-control"
          data-testid="postalCodeInput"
          formControlName="zip_code"
          id="postalCodeInput"
          mask="0*"
          [maxlength]="validators.buyer_detail.zip_code.domestic.maxLength"
          [minlength]="validators.buyer_detail.zip_code.domestic.minLength"
          type="text"
          [readonly]="!form.controls['subdistrict'].value"
          [ngClass]="{ 'is-invalid': submitted && form.controls['zip_code'].errors }"
        >
      </div>
    </div>
  </form>
</ng-template>

<ng-template #foreignerEl>
  <form class="form-wrapper" [formGroup]="form">
    <div class="row mb-3 g-2">
      <div class="col-12">
        <textarea
          class="form-control"
          data-testid="addressTextarea"
          id="addressTextarea"
          formControlName="address"
          [maxlength]="validators.buyer_detail.address.maxLength"
          rows="7"
          [ngClass]="{ 'is-invalid': submitted && form.controls['address'].errors }"
        ></textarea>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label required-field" for="countrySelect">{{ 'NEW-TRANSLATE.COMMON.COUNTRY' | translate }}</label>
        <ng-select
          class="form-control"
          [clearable]="false"
          data-testid="countrySelect"
          formControlName="country"
          id="countrytSelect"
          [items]="countries"
          [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-COUNTRY' | translate"
          [readonly]="isLoadingCountry"
          [searchFn]="customSearchLocationValue"
          (change)="onChangeCountry($event)"
          [ngClass]="{ 'is-invalid': submitted && form.controls['country'].errors  }"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ language === 'th' ? item.name_th : item.name_en }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label required-field" for="postalCodeInput" [ngClass]="{ 'required-field': form.controls['country'].value?.country_code == 'TH' }">{{ 'NEW-TRANSLATE.COMMON.POSTAL-CODE' | translate }}</label>
        <input
          class="form-control"
          data-testid="postalCodeInput"
          formControlName="zip_code"
          id="postalCodeInput"
          [mask]="form.controls['country'].value?.country_code == 'TH' ? '0*' : null"
          [maxlength]="form.controls['country'].value?.country_code == 'TH' ? validators.buyer_detail.zip_code.domestic.maxLength : validators.buyer_detail.zip_code.international.maxLength"
          [minlength]="form.controls['country'].value?.country_code == 'TH' ? validators.buyer_detail.zip_code.domestic.minLength : null"
          type="text"
          [readonly]="!form.controls['country'].value"
          [ngClass]="{ 'is-invalid': submitted && form.controls['zip_code'].errors }"
        >
      </div>
    </div>
  </form>
</ng-template>

