import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { ApiService } from '../../service/api.service';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject, takeUntil } from 'rxjs';
import { LanguageType } from '../../shared/interfaces';
import { HandleTranslateService } from '../../shared/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;
  
  public username: string | null  = '';
  public toggleNav: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private handleTranslateService: HandleTranslateService
  ) {
    this.getTimeOut();
    this.subscribeToServices();
  }

  ngOnInit() {
    let username = localStorage.getItem('username');
    this.username = username;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  public onChangeLanguage(language: LanguageType): void {
    this.handleTranslateService.setLanguage(language);
  }

  public isKeyinPermission(): boolean {
    return this.authenticationService.isKeyinPermission();
  }

  public isSettingPermission(): boolean {
    return this.authenticationService.isSettingPermission();
  }

  public isSubmitToRdPermission(): boolean {
    return this.authenticationService.isSubmitToRdPermission();
  }

  public isRevenueDocumentPermission(): boolean {
    return this.authenticationService.isRevenueDocumentPermission();
  }

  public isSalesTaxReportPermission(): boolean {
    return this.authenticationService.isSalesTaxReportPermission();
  }

  public isDocumentSearchPermission(): boolean {
    return this.authenticationService.isDocumentSearchPermission();
  }

  public isRdSummaryPermission(): boolean {
    return this.authenticationService.isRdSummaryPermission();
  }

  public isFeeEnginePermission(): boolean {
    return this.authenticationService.isFeeEnginePermission();
  }

  public isDocumentUploadPermission(): boolean {
    return this.authenticationService.isDocumentUploadPermission();
  }

  public isBulkTaxReportPermission(): boolean {
    return this.authenticationService.isBulkTaxReportPermission();
  }

  public isAnnouncementPermission(): boolean {
    return this.authenticationService.isAnnouncementPermission();
  }

  public isDashbaordPermission(): boolean {
    return this.authenticationService.isDashbaordPermission();
  }

  public isMonitoringPermission(): boolean {
    return this.authenticationService.isMonitoringPermission();
  }

  public isEmailDashboardPermission(): boolean {
    return this.authenticationService.isEmailDashboardPermission();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  canUserSignDoc() {
    return this.authenticationService.isAccountant();
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  isUIH() {
    return this.authenticationService.isUIH();
  }

  isAccountant() {
    return this.authenticationService.isAccountant();
  }

  isAccountantAdmin() {
    return this.authenticationService.isAccountantAdmin();
  }

  isMaker(){
    return this.authenticationService.isMaker();
  }

  isChecker(){
    return this.authenticationService.isChecker();
  }

  isSuperChecker() {
    return this.authenticationService.isSuperChecker();
  }

  isViewer() {

  }

  isViewerOperation() {
    return this.authenticationService.isViewerOperation();
  }

  isViewerImpAndHelp() {
    return this.authenticationService.isViewerImpAndHelp();
  }

  isIT() {
    return this.authenticationService.isIT();
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  getRole() {
    return localStorage.getItem('group');
  }

  isSCBBCMManageUser() {
    return this.authenticationService.isSuperMaker() || this.authenticationService.isMaker() || this.authenticationService.isSuperChecker()
      || this.authenticationService.isChecker();
  }

  isSCBBCMViewer() {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  isNotSCIUser() {
    return !this.authenticationService.isSCIUser();
  }

  logout() {
    this.apiService.userLogout().subscribe();
  }

  getTimeOut() {
    this.idle.setIdle(14 * 60);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      this.apiService.logout().subscribe(() => this.router.navigate(['/login']));
      this.idle.onTimeout.observers.length = 0;
      setTimeout(() => {
        alert(this.handleTranslateService.translate('TEXT.TIME-OUT'));
      }, 900);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
    this.keepalive.interval(30);
    this.idle.watch();
  }

}
