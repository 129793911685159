import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginAdminComponent } from '../login-admin/login-admin.component';
import { PageExpiredComponent } from '../core/page-expired/page-expired.component';
import { MainComponent } from './main.component';
import { AuthGuard } from '../service/guards/auth.guard';


import { routes as ReportRoutes } from '../features/report/report-routing.module';
import { routes as ProfileRoutes } from '../features/profile/profile-routing.module';
import { routes as BillingRoutes } from '../features/billing/billing-routing.module';
import { routes as TaxB2pRoutes} from '../features/tax-b2p/tax-b2p-routing.module';
import { routes as Usermanagementroutes} from '../features/user-management/user-management-routing.module';
import { routes as PurgeRoutes } from '../features/purge/purge-routing.module';
import { routes as FeeDebitAccountRoutes } from '../features/fee-debit-account/fee-debit-account-routing.module';
import { routes as ApprovalHistoryRoutes } from '../features/approval-history/approval-history-routing.module'

import { routes as KeyinRoutes } from '../features/keyin';
import { routes as SettingRoutes } from '../features/setting';
import { routes as SubmitToRdRoutes } from '../features/submit-to-rd';
import { routes as RdSummaryRoutes } from '../features/rd-summary';
import { routes as BulkTaxReportRoute } from '../features/bulk-tax-report';
import { routes as AnnouncementRoute } from '../features/announcement';
import { routes as MonitoringRoute } from '../features/monitoring';
import { routes as DocumentUploadRoutes } from '../features/document';
import { routes as SearchRoutes } from '../features/search';
import { routes as TaxReportRoutes} from '../features/tax-report';
import { routes as ZipFileRoutes } from '../features/zip-file';
import { routes as FeeEngineroutes} from '../features/fee-engine';
import { routes as Emaildashboardroutes} from '../features/email-dashboard';
import { routes as DashboardRoutes } from '../features/dashboard';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginAdminComponent },
  { path: 'expire', component: PageExpiredComponent },
  {
    path: '',
    component: MainComponent,
    canActivate: [ AuthGuard ],
    children: [
      ...DocumentUploadRoutes,
      ...ReportRoutes,
      ...SearchRoutes,
      ...DashboardRoutes,
      ...BillingRoutes,
      ...ProfileRoutes,
      ...TaxReportRoutes,
      ...ZipFileRoutes,
      ...TaxB2pRoutes,
      ...Emaildashboardroutes,
      ...Usermanagementroutes,
      ...PurgeRoutes,
      ...FeeEngineroutes,
      ...FeeDebitAccountRoutes,
      ...ApprovalHistoryRoutes,
      ...KeyinRoutes,
      ...SettingRoutes,
      ...SubmitToRdRoutes,
      ...RdSummaryRoutes,
      ...BulkTaxReportRoute,
      ...AnnouncementRoute,
      ...MonitoringRoute
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
